// React
import React from 'react';

// Tools / Library
import Loader from 'react-loader-spinner';
import {fetchWithTimeout, escStr} from '../Utils'
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';

// Components
import UnknownScreen from './UnknownScreen';
import Navbar from './Navbar';
import Box from './Box';


class PostViewer extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	postViewerOptions = {
		loaderColor: "#0037ff",
		loaderSize: 80,
	};
	selectedPostIndex = -1;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			step: '',
			notice: '',
			contentLoadedId : 0,
			contentLoaded : false,
			content : '',
		}

		// Bind this to functions
		this.getPostContent = this.getPostContent.bind(this);
		this.validatePassword = this.validatePassword.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;

		// Init Post Index
		for (let i=0; i<this.props.appData.contents.length; i++) {
			if (this.props.appData.contents[i].id === parseInt(this.props.postId, 10)) { this.selectedPostIndex = i; }
		}
		if (this.selectedPostIndex === -1) { this.setState({step: 'unknown'}); }
		else if (this.props.appData.contents[this.selectedPostIndex].protected === 1) {
			if (typeof(Storage) !== 'undefined') {
				const lsEspacePartenaireMD5 = localStorage.getItem('espacePartenaireMD5');
				if (typeof(lsEspacePartenaireMD5) !== 'undefined'
					&& lsEspacePartenaireMD5 !== null
					&& lsEspacePartenaireMD5 ===  this.props.appData.espacePartenairePassword
					) {
					this.setState({step: 'loading'});
				}
				else { this.setState({step: 'forbidden'}); }
			}
			else { this.setState({step: 'forbidden'}); }
		}
		else { this.setState({step: 'loading'}); }

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {
		if (this.state.step === 'display' && this.state.contentLoadedId !== 0 && this.state.contentLoadedId === this.props.postId) {
			this.displayPostHtml();
		}
		if (this.state.step === 'display' && this.state.contentLoadedId !== 0 && this.state.contentLoadedId !== this.props.postId) {
			for (let i=0; i<this.props.appData.contents.length; i++) {
				if (this.props.appData.contents[i].id === parseInt(this.props.postId, 10)) { this.selectedPostIndex = i; }
			}
			this.setState({step: 'loading', content: ''});
		}
		if (this.state.step === 'loading') {
			this.getPostContent();
		}
	}


	///////////////////////////////////////
	// Render
	render() {
		let pageClass = "page-postViewer";
		let pageContent = "";

		// Scroll to top
		window.scrollTo(0, 0);

		///////////////////////////////////////
		// Display
		if (this.state.step === 'display'
			|| this.state.step === 'loading') {

			// Feature Image
			let imageUrl = this.props.appData.contents[this.selectedPostIndex].image;
			let imageWidth = this.props.appData.contents[this.selectedPostIndex].image_width;
			let imageHeight = this.props.appData.contents[this.selectedPostIndex].image_height;
			if (imageUrl.length === 0) {
				imageUrl = this.props.appData.default_image.url;
				imageWidth = this.props.appData.default_image.width;
				imageHeight = this.props.appData.default_image.height;
			}

			const feature =
				<Box
					boxClass="ww-box--feature ww-box__nooverlay"
					boxImage={imageUrl}
					boxImageWidth={imageWidth}
					boxImageHeight={imageHeight}
				/>;

			// Post Content
			let content = "";
			if (this.state.contentLoaded === true && this.state.content.length > 0) {
				content = <div dangerouslySetInnerHTML={{__html: this.state.content}}></div>
			}
			else if (this.state.contentLoaded === true && this.state.content.length === 0) {
				content =
					<div>
						<p>{escStr(this.props.appData.contents[this.selectedPostIndex].excerpt)}</p>
						<p><em>{escStr(this.props.appData.tStrings.connect_internet)}</em></p>
					</div>;
			}
			else if (this.state.contentLoaded === false) {
				content =
					<Loader
						className="ww-loader"
						type="Oval"
						color={this.postViewerOptions.loaderColor}
						height={this.postViewerOptions.loaderSize}
						width={this.postViewerOptions.loaderSize}
					/>;
			}

			// Share
			let share = '';
			if (typeof(this.props.appData.contents[this.selectedPostIndex].link) !== 'undefined'
				&& this.props.appData.contents[this.selectedPostIndex].link.length > 0) {
				const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.props.appData.contents[this.selectedPostIndex].link;
				const twitterUrl = 'https://twitter.com/intent/tweet/?text=' + this.props.appData.contents[this.selectedPostIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.contents[this.selectedPostIndex].link;
				const linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&title=' + this.props.appData.contents[this.selectedPostIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.contents[this.selectedPostIndex].link;

				share =
					<div className="ww-share">
						<p className="ww-share__title">{escStr(this.props.appData.tStrings.partager)}</p>
						<ul>
							<li><a href={facebookUrl} target="_blank" rel="noreferrer noopener"><FaFacebookF size="24px" /></a></li>
							<li><a href={twitterUrl} target="_blank" rel="noreferrer noopener"><FaTwitter size="22px" /></a></li>
							<li><a href={linkedInUrl} target="_blank" rel="noreferrer noopener"><FaLinkedinIn size="22px" /></a></li>
						</ul>
					</div>;
			}

			// Related Posts
			let relatedPost1 = "";
			if (this.selectedPostIndex !== -1
				&& this.props.appData.contents[this.selectedPostIndex].related_posts.length > 0) {
				let imageClass = 'ww-box--related';
				let imageUrl = this.props.appData.contents[this.selectedPostIndex].related_posts[0].image;
				let imageWidth = this.props.appData.contents[this.selectedPostIndex].related_posts[0].image_width;
				let imageHeight = this.props.appData.contents[this.selectedPostIndex].related_posts[0].image_height;
				if (imageUrl.length === 0) {
					imageUrl = this.props.appData.default_image.url;
					imageWidth = this.props.appData.default_image.width;
					imageHeight = this.props.appData.default_image.height;
					imageClass = 'ww-box--related ww-box__nooverlay';
				}
				let relatedPostBox1 =
					<Box
						boxClass={imageClass}
						boxImage={imageUrl}
						boxImageWidth={imageWidth}
						boxImageHeight={imageHeight}
					/>;

				let relatedPostTitle1 =
					<div className="ww-box__under">
						<p className="ww-box__title">{escStr(this.props.appData.contents[this.selectedPostIndex].related_posts[0].title)}</p>
					</div>;

				relatedPost1 =
					<div className="ww-postViewer__relatedPosts__box" onClick={ () => this.props.handleClickEvent("related_post", "post/" + this.props.appData.contents[this.selectedPostIndex].related_posts[0].id, this.props.historyProps) }>
						{relatedPostBox1}
						{relatedPostTitle1}
					</div>;
			}

			let relatedPost2 = "";
			if (this.selectedPostIndex !== -1
					&& this.props.appData.contents[this.selectedPostIndex].related_posts.length > 1) {
				let imageClass = 'ww-box--related';
				let imageUrl = this.props.appData.contents[this.selectedPostIndex].related_posts[1].image;
				let imageWidth = this.props.appData.contents[this.selectedPostIndex].related_posts[1].image_width;
				let imageHeight = this.props.appData.contents[this.selectedPostIndex].related_posts[1].image_height;
				if (imageUrl.length === 0) {
					imageUrl = this.props.appData.default_image.url;
					imageWidth = this.props.appData.default_image.width;
					imageHeight = this.props.appData.default_image.height;
					imageClass = 'ww-box--related ww-box__nooverlay';
				}
				let relatedPostBox2 =
					<Box
						boxClass={imageClass}
						boxImage={imageUrl}
						boxImageWidth={imageWidth}
						boxImageHeight={imageHeight}
					/>;

				let relatedPostTitle2 =
					<div className="ww-box__under">
						<p className="ww-box__title">{escStr(this.props.appData.contents[this.selectedPostIndex].related_posts[1].title)}</p>
					</div>;

				relatedPost2 =
					<div className="ww-postViewer__relatedPosts__box" onClick={ () => this.props.handleClickEvent("related_post", "post/" + this.props.appData.contents[this.selectedPostIndex].related_posts[1].id, this.props.historyProps) }>
						{relatedPostBox2}
						{relatedPostTitle2}
					</div>;
			}

			let relatedPostContent = "";
			if (this.selectedPostIndex !== -1
				&& this.props.appData.contents[this.selectedPostIndex].related_posts.length > 0) {
				relatedPostContent =
					<div className="ww-postViewer__relatedPosts">
						<div className="ww-postViewer__relatedPosts__content">
							<h2>{this.props.appData.tStrings.articles_lies}</h2>
							<div className="ww-postViewer__relatedPosts__boxes">
								{relatedPost1}
								{relatedPost2}
							</div>
						</div>
					</div>;
			}

			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{feature}
					<h1>{escStr(this.props.appData.contents[this.selectedPostIndex].title)}</h1>
					<div id="postViewer__content">{content}</div>
					{share}
					{relatedPostContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;

		}


		///////////////////////////////////////
		// Protected
		else if (this.state.step === 'forbidden') {

			// Feature Image
			let imageUrl = this.props.appData.contents[this.selectedPostIndex].image;
			let imageWidth = this.props.appData.contents[this.selectedPostIndex].image_width;
			let imageHeight = this.props.appData.contents[this.selectedPostIndex].image_height;
			if (imageUrl.length === 0) {
				imageUrl = this.props.appData.default_image.url;
				imageWidth = this.props.appData.default_image.width;
				imageHeight = this.props.appData.default_image.height;
			}

			const feature =
				<Box
					boxClass="ww-box--feature ww-box__nooverlay"
					boxImage={imageUrl}
					boxImageWidth={imageWidth}
					boxImageHeight={imageHeight}
				/>;

			let protectedContent =
				<div className="ww-passwordForm ww-form">
					<p>{escStr(this.props.appData.tStrings.contenu_protege)}</p>
					<input id="password" type="password" />
					<button className="ww-button--primary" type="button" onClick={this.validatePassword}>{escStr(this.props.appData.tStrings.valider)}</button>
					<p>{this.state.notice}</p>
				</div>;


			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{feature}
					<h2 className="ww-boxList__title">{escStr(this.props.appData.contents[this.selectedPostIndex].title)}</h2>
					{protectedContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;
		}


		///////////////////////////////////////
		// Unknown
		else if (this.state.step === 'unknown') {
			pageClass = "page-postViewer page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Get Page Content from Server
	getPostContent() {

		fetchWithTimeout(this.props.appOptions.backofficeUrl + '?version=' + this.props.appOptions.version + '&order=3&apikey=' + this.props.appOptions.backofficeAPIKey + '&language=' + this.props.splashData.code + '&post_id=' + this.props.postId, {}, 10000)
			.then(response => response.text())
			.then(result => {
				if (this.mounted === true) {
					this.setState({
						step : 'display',
						contentLoadedId : this.props.postId,
						contentLoaded : true,
						content : result
					});
				}
			}).catch(error => {
				if (this.mounted === true) {
					this.setState({
						step : 'display',
						contentLoadedId : this.props.postId,
						contentLoaded : true,
						content : ''
					});
				}
			});

	}


	///////////////////////////////////////
	// Adjust Page HTML
	displayPostHtml() {

		if (this.state.contentLoaded === true && this.state.content.length > 0) {
			let contentDiv = document.querySelector("#postViewer__content");
			if (contentDiv) {
				contentDiv.innerHTML = this.state.content;

				let iframes = document.getElementsByTagName('iframe');
				for (let i=0;i<iframes.length;i++) {
					if (iframes[i].src.indexOf('youtu') !== -1) {
						let wrapper = document.createElement('div');
						wrapper.classList.add("ww-youtubeWrapper");
						iframes[i].parentNode.insertBefore(wrapper, iframes[i]);
						wrapper.appendChild(iframes[i]);
					}
					if (iframes[i].id === 'alyzee') {
						let wrapper = document.createElement('div');
						wrapper.classList.add("ww-alyzeeWrapper");
						iframes[i].parentNode.insertBefore(wrapper, iframes[i]);
						wrapper.appendChild(iframes[i]);
					}
				}
			}
		}

	}


	///////////////////////////////////////
	// Validate Password
	validatePassword() {

		let password = document.getElementById('password');
		if (password !== null) {
			if (this.$md5(password.value) === this.props.appData.espacePartenairePassword) {
				if (typeof(Storage) !== 'undefined') {
					localStorage.setItem('espacePartenaireMD5', this.props.appData.espacePartenairePassword);
					this.setState({
						step: 'loading',
						notice: '',
						contentLoadedId : 0,
						contentLoaded : false,
						content : '',
					});
					return;
				}
			}
		}

		this.setState({notice: escStr(this.props.appData.tStrings.contenu_mot_passe_invalide)});

	}

}

export default PostViewer;
