// React
import React from 'react';
import { NavLink } from 'react-router-dom';

// Ressources
import {escStr} from '../Utils'
import logoUnkown from '../Images/logo-navbar.png';


class UnknownScreen extends React.Component {

	///////////////////////////////////////
	// Render
	render() {
		return(
			<div className="page-unknown">
				<div className="page-unknown__logo">
					<img src={logoUnkown} alt="Logo" />
				</div>
				<div className="page-content">
					<h2>{escStr(this.props.appData.tStrings.page_inconnue)}</h2>
					<p><NavLink to={this.props.appOptions.basePath + 'dispatch'}><span className="ww-button--secondary">{escStr(this.props.appData.tStrings.retour)}</span></NavLink></p>
				</div>
			</div>
		);
	}

}

export default UnknownScreen;
