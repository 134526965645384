import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import md5 from 'js-md5';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

React.Component.prototype.$md5 = md5

ReactDOM.render(<App />, document.getElementById('page'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
