// React
import React from 'react';
import {MapContainer, MapConsumer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';

// Tools / Library
import {escStr} from '../Utils'
import {isEmpty} from 'lodash'
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {BiTargetLock} from 'react-icons/bi';
import {FaMapMarkedAlt} from 'react-icons/fa';

// Components
import Navbar from './Navbar';
import ListSelect from './ListSelect';

import mapImgUser from '../Images/map-user.svg';
import mapImgMarker from '../Images/map-marker.svg';

const nl2br = require('react-nl2br');


class EventsScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	userLocation = null;
	events = [];
	map = null;
	mapOptions = {
		mapOpacity: 0.6,
		mapMarkerSize: [28, 40],
		mapMarkerAnchor: [14, 40],
	};

	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
			displayMode: 0,
			selectedDate: 0,
		};

		// Bind this to functions
		this.handleSelect = this.handleSelect.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.userLocation = location;
							this.setState({ geolocation : true });
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({ geolocation : true });
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({ geolocation : true });
			}
		}
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {

		// Update Map Position
		if (this.map !== null) {
			let markersArray = [];

			if (this.state.displayMode === 0 || this.userLocation === null) {
				if (this.userLocation !== null) { markersArray.push(L.marker([this.userLocation.coords.latitude, this.userLocation.coords.longitude])); }
				if (!isEmpty(this.events)) {
					for (let i=0; i<this.events.length; i++) { markersArray.push(L.marker([this.events[i].latitude, this.events[i].longitude])); }
				}
				let group = L.featureGroup(markersArray);
				this.map.fitBounds(group.getBounds(), {padding: [40, 40]});
			}
			else {
				this.map.setView([this.userLocation.coords.latitude, this.userLocation.coords.longitude], 16.5);
			}
		}
	}


	///////////////////////////////////////
	// Render
	render() {
		let pageClass = "page-events";
		let pageContent = "";

		///////////////////////////////////////
		// Filter Date
		const string3mois = (typeof(this.props.appData.tStrings.agenda_3_prochains_mois) === 'undefined') ? '3 prochains mois' : this.props.appData.tStrings.agenda_3_prochains_mois;
		const filterDateValues = [
			{ id : 0, name : escStr(this.props.appData.tStrings.toutes) },
			{ id : 1, name : escStr(this.props.appData.tStrings.agenda_aujourdhui) },
			{ id : 2, name : escStr(this.props.appData.tStrings.agenda_7_prochains_jours) },
			{ id : 3, name : escStr(string3mois) },
		];
		const filterDate =
			<ListSelect
				handleSelect={this.handleSelect}
				listId="agenda-dates"
				listTitle={escStr(this.props.appData.tStrings.agenda_filtre_date)}
				listItems={filterDateValues}
				listSelectedId={this.state.selectedDate}
			/>;


		///////////////////////////////////////
		// Build Events List
		this.events = [];
		const now = Date.now() / 1000;
		for (let i=0; i<this.props.appData.agenda.length; i++) {
			// Filtre Date
			if (this.state.selectedDate === 1) {
				if (this.props.appData.agenda[i].timestamp_start < now && now < (this.props.appData.agenda[i].timestamp_end + 86400)) { this.events.push(this.props.appData.agenda[i]); }
			}
			else if (this.state.selectedDate === 2) {
				if ((this.props.appData.agenda[i].timestamp_start - 7 * 86400) < now && now < (this.props.appData.agenda[i].timestamp_end + 86400)) { this.events.push(this.props.appData.agenda[i]); }
			}
			else if (this.state.selectedDate === 3) {
				if ((this.props.appData.agenda[i].timestamp_start - 92 * 86400) < now && now < (this.props.appData.agenda[i].timestamp_end + 86400)) { this.events.push(this.props.appData.agenda[i]); }
			}
			else { this.events.push(this.props.appData.agenda[i]); }
		}


		///////////////////////////////////////
		// User position
		const mapUser = new L.Icon({
			iconUrl: mapImgUser,
			iconSize: [20, 20],
			iconAnchor: [10, 10]
		});
		let userMarker = "";
		if (this.state.geolocation === true && this.userLocation !== null) {
			userMarker = <Marker position={[this.userLocation.coords.latitude, this.userLocation.coords.longitude]} icon={mapUser}></Marker>;
		}


		///////////////////////////////////////
		// Map Center button
		let centerButton = "";
		// Map Fit
		if (this.state.displayMode === 0) {
			centerButton = <div className="ww-events__mapCenterButton" onClick={ () => this.setState({ displayMode : 1 }) }><FaMapMarkedAlt size="18px" /></div>;
		}
		// User center
		if (this.state.displayMode === 1) {
			centerButton = <div className="ww-events__mapCenterButton" onClick={ () => this.setState({ displayMode : 0 }) }><BiTargetLock size="26px" /></div>;
		}


		///////////////////////////////////////
		// Map
		const mapMarker = new L.Icon({
			iconUrl: mapImgMarker,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});

		// Map
		let eventsMap = "";
		if (!isEmpty(this.events)) {
			eventsMap =
				<div className="ww-events__map">
					<MapContainer center={[0, 0]} zoom={16} zoomSnap={0.1}>
						<MapConsumer>{(map) => { this.map = map; return null; }}</MapConsumer>
						<TileLayer url={this.props.appData.mapProvider} opacity={this.mapOptions.mapOpacity} />
						{userMarker}
						{this.events.map( (event, count) => {
							let partenaire = '';
							for (let p=0; p<this.props.appData.partenaires.length; p++) {
								if (this.props.appData.partenaires[p].id === event.partenaire) {
									partenaire = <p className="ww-eventsInfoWindow__partenaire" onClick={ () => { this.props.handleClickEvent('partenaires_box', 'partenaire/' + this.props.appData.partenaires[p].id, this.props.historyProps); }}>{escStr(this.props.appData.partenaires[p].title)}<br /><em>({escStr(this.props.appData.tStrings.agenda_cliquer_voir_fiche)})</em></p>;
								}
							}
							const link = (event.url.length === 0) ? '' : <p className="ww-eventsInfoWindow__link"><a target="_blank" rel="noopener noreferrer" href={event.url}>{escStr(this.props.appData.tStrings.en_savoir_plus)}</a></p>;
							const image = (event.image.length === 0) ? '' : <div className="ww-eventsInfoWindow__image"><img src={event.image} alt="" /></div>;
							const date = (event.timestamp_start === event.timestamp_end) ? <p className="ww-eventsInfoWindow__date">{event.date_start}</p> : <p className="ww-eventsInfoWindow__date">{event.date_start} - {event.date_end}</p>;
							const infoWindowClass = (event.image.length === 0) ? 'ww-eventsInfoWindow' : 'ww-eventsInfoWindow ww-eventsInfoWindow--image';
							const popup =
								<div className={infoWindowClass}>
									{partenaire}
									<p className="ww-eventsInfoWindow__title">{escStr(event.titre)}</p>
									{date}
									<p className="ww-eventsInfoWindow__description">{nl2br(escStr(event.description))}</p>
									{link}
									{image}
								</div>;
							return (<Marker key={count} position={[event.latitude, event.longitude]} icon={mapMarker}><Popup maxWidth={260} maxHeight={400}>{popup}</Popup></Marker>);
						})}
					</MapContainer>
					{centerButton}
				</div>;
		}
		else {
			eventsMap = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
		}


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'agenda') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}

		pageContent =
			<div className="page-content">
				<Navbar
					historyProps={this.props.historyProps}
					handleClickEvent={this.props.handleClickEvent}
					appOptions={this.props.appOptions}
					appData={this.props.appData}
					splashData={this.props.splashData}
				/>
				<h2 className="ww-boxList__title ww-marginBottom--none">{escStr(title)}</h2>
				{filterDate}
				{eventsMap}
				<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
					<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
				</div>
			</div>;


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Handle Select Date
	handleSelect(listId, value) {
		if (listId === "agenda-dates") {
			this.setState({ selectedDate : value });
		}
	}

}

export default EventsScreen;
