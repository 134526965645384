// React
import React from 'react';

// Tools / Library
import {escStr} from '../Utils';
import {isEmpty} from 'lodash';
import {FaFacebookF, FaTwitter, FaLinkedin, FaYoutube, FaInstagram} from 'react-icons/fa';
import {IoIosGlobe} from 'react-icons/io';

// Ressources
import logoNavbar from '../Images/logo-navbar.png';


class Navbar extends React.Component {

	///////////////////////////////////////
	// Variables
	navbarOptions = {
		navbarHomePath : "dispatch",
		navbarLogoDisplay : true,
		navbarLogoSize : "150px",
		navbarLogoPosition : "0px",
	};


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = { opened : false };

		// Bind this to functions
		this.handleToggleClick = this.handleToggleClick.bind(this);

	}


	///////////////////////////////////////
	// Render
	render() {

		if (typeof(this.props.appData) === 'undefined' || isEmpty(this.props.appData)) { return ''; }

		// Navbar Class
		const navbarClass = (this.state.opened === true) ? 'ww-navbar--visible' : '';

		// Logo
		let logo = "";
		if (this.navbarOptions.navbarLogoDisplay === true) {
			logo =
				<div id="ww-navbar__logo" style={{width: this.navbarOptions.navbarLogoSize, left: this.navbarOptions.navbarLogoPosition}} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', this.navbarOptions.navbarHomePath, this.props.historyProps) } }>
					<img src={logoNavbar} alt="Logo" style={{width: this.navbarOptions.navbarLogoSize}} />
				</div>;
		}

		// Toggle Text
		const menu_toggle_text =
			<div id="ww-navbar__toggleText" onClick={ (e) => this.handleToggleClick(e) }>{this.props.appData.tStrings.menu}</div>;

		// Toggle
		const menu_toggle =
			<div id="ww-navbar__toggle" onClick={ (e) => this.handleToggleClick(e) }>
				<span className="ww-hamburger__inner"></span>
			</div>;

		// Dispatch
		const menu_dispatch = <li key="nav_dest" onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'dispatch', this.props.historyProps) } }>{escStr(this.props.splashData.content_title_main)}</li>;

		// Social Content
		const social_website = (typeof(this.props.appData.social.website) !== 'undefined' && this.props.appData.social.website.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.website}><IoIosGlobe /></a></li> : '';
		const social_facebook = (typeof(this.props.appData.social.facebook) !== 'undefined' && this.props.appData.social.facebook.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.facebook}><FaFacebookF /></a></li> : '';
		const social_twitter = (typeof(this.props.appData.social.twitter) !== 'undefined' && this.props.appData.social.twitter.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.twitter}><FaTwitter /></a></li> : '';
		const social_linkedin = (typeof(this.props.appData.social.linkedin) !== 'undefined' && this.props.appData.social.linkedin.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.linkedin}><FaLinkedin /></a></li> : '';
		const social_youtube = (typeof(this.props.appData.social.youtube) !== 'undefined' && this.props.appData.social.youtube.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.youtube}><FaYoutube /></a></li> : '';
		const social_instagram = (typeof(this.props.appData.social.instagram) !== 'undefined' && this.props.appData.social.instagram.length > 0) ? <li><a target="_blank" rel="noreferrer noopener" href={this.props.appData.social.instagram}><FaInstagram /></a></li> : '';

		return(
			<header>
				<div id="ww-navbar" className={navbarClass}>
					<div id="ww-navbar__bar">
						{logo}
						{menu_toggle_text}
						{menu_toggle}
					</div>
					<div id="ww-navbar__content">
						<div className="ww-navbar__menu">
							<ul>
								{menu_dispatch}
								{this.props.appData.contents.map( item => {
									if (item.type !== 'categorie-level1') { return ''; }
									if (item.special === 'partenaires') {
										return(
											<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'partenaires', this.props.historyProps) } }>{escStr(item.title)}</li>
										);
									}
									else if (item.special === 'escales') {
										return(
											<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'escales', this.props.historyProps) } }>{escStr(item.title)}</li>
										);
									}
									else if (item.special === 'agenda') {
										return(
											<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'evenements', this.props.historyProps) } }>{escStr(item.title)}</li>
										);
									}
									else if (item.special === 'signalement') {
										return(
											<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'signalement', this.props.historyProps) } }>{escStr(item.title)}</li>
										);
									}
									else if (item.special === 'visites') {
										if (this.props.appData.visites_clock === true) {
											return(
												<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'visites_duree', this.props.historyProps) } }>{escStr(item.title)}</li>
											);
										}
										else {
											return(
												<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'visites', this.props.historyProps) } }>{escStr(item.title)}</li>
											);
										}
									}
									else {
										return(
											<li key={item.id} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'category/' + item.id, this.props.historyProps) } }>{escStr(item.title)}</li>
										);
									}
								})}
								<li key={9999} onClick={ () => { this.setState({ opened : false }); this.props.handleClickEvent('navbar', 'help', this.props.historyProps) } }>{escStr(this.props.appData.tStrings.aide)}</li>
							</ul>
							<div id="ww-navbar__social">
								<h3>{this.props.appData.tStrings.nous_suivre}</h3>
								<ul>
									{social_website}
									{social_facebook}
									{social_twitter}
									{social_linkedin}
									{social_youtube}
									{social_instagram}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</header>
		);

	}


	///////////////////////////////////////
	// Click on Toggle
	handleToggleClick(e) {
		let body = document.querySelector("body");
		if (body) { body.classList.toggle("ww-navbar--visible"); }
		this.setState({ opened : !this.state.opened });
	}

}

export default Navbar;
