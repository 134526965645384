// React
import React from 'react';

class Dialog extends React.Component {

	///////////////////////////////////////
	// Render
	render() {
		let buttonClass = "ww-dialog__close ww-button--primary";
		if (typeof(this.props.closeDisabled) !== 'undefined' && this.props.closeDisabled === true) { buttonClass = 'ww-dialog__close ww-button--primary ww-dialog--disabled'; }

		return(
			<div className="ww-dialog">
				<div className="ww-dialog__container">
					<div className="ww-dialog__content">{this.props.dialogContent}</div>
					<div className={buttonClass} onClick={ () => {
						if (typeof(this.props.closeDisabled) !== 'undefined' && this.props.closeDisabled === true) { return; }
						else { return (this.props.dialogClickEvent('dialog', this.props.dialogId)); }}}>{this.props.dialogClose}</div>
				</div>
			</div>
		);

	}

}

export default Dialog;
