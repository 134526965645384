// React
import React from 'react';
import {MapContainer, MapConsumer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';

// Tools / Library
import {escStr} from '../Utils'
import {isEmpty} from 'lodash'
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';
import ListSelect from './ListSelect';

import mapImgUser from '../Images/map-user.svg';
import mapAdmin from '../Images/administratif.svg';
import mapBorne from '../Images/borne.svg';
import mapEssence from '../Images/essence.svg';
import mapParking from '../Images/parking.svg';
import mapSecurite from '../Images/securite.svg';
import mapToilettes from '../Images/toilettes.svg';
import mapTourisme from '../Images/tourisme.svg';

const nl2br = require('react-nl2br');


class InfosScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	userLocation = null;
	infos = [];
	map = null;
	mapOptions = {
		mapOpacity: 0.6,
		mapMarkerSize: [28, 40],
		mapMarkerAnchor: [14, 40],
	};

	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
			selectedType: 0,
		};

		// Bind this to functions
		this.handleSelect = this.handleSelect.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.userLocation = location;
							this.setState({ geolocation : true });
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({ geolocation : true });
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({ geolocation : true });
			}
		}
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {

		// Update Map Position
		if (this.map !== null) {
			/*let markersArray = [];
			if (this.userLocation !== null) { markersArray.push(L.marker([this.userLocation.coords.latitude, this.userLocation.coords.longitude])); }
			if (!isEmpty(this.infos)) {
				for (let i=0; i<this.infos.length; i++) { markersArray.push(L.marker([this.infos[i].latitude, this.infos[i].longitude])); }
			}
			let group = L.featureGroup(markersArray);
			this.map.fitBounds(group.getBounds(), {padding: [40, 40]});*/
			if (this.userLocation !== null) {
				this.map.setView([this.userLocation.coords.latitude, this.userLocation.coords.longitude], 15);
			}
		}
	}


	///////////////////////////////////////
	// Render
	render() {
		let pageClass = "page-infos";
		let pageContent = "";

		///////////////////////////////////////
		// Filter Type
		const stringParking = (typeof(this.props.appData.tStrings.infos_parking) === 'undefined') ? 'Parking' : this.props.appData.tStrings.infos_parking;
		const stringToilettes = (typeof(this.props.appData.tStrings.infos_toilettes) === 'undefined') ? 'Toilettes' : this.props.appData.tStrings.infos_toilettes;
		const stringStation = (typeof(this.props.appData.tStrings.infos_station) === 'undefined') ? 'Station-service' : this.props.appData.tStrings.infos_station;
		const stringSecurite = (typeof(this.props.appData.tStrings.infos_securite) === 'undefined') ? 'Poste de sécurité' : this.props.appData.tStrings.infos_securite;
		const stringAdmin = (typeof(this.props.appData.tStrings.infos_admin) === 'undefined') ? 'Administratif' : this.props.appData.tStrings.infos_admin;
		const stringTourisme = (typeof(this.props.appData.tStrings.infos_tourisme) === 'undefined') ? 'Office du tourisme' : this.props.appData.tStrings.infos_tourisme;
		const stringBorne = (typeof(this.props.appData.tStrings.infos_borne) === 'undefined') ? 'Borne électrique' : this.props.appData.tStrings.infos_borne;
		const filterTypeValues = [
			{ id : 0, name : escStr(this.props.appData.tStrings.toutes) },
			{ id : 1, name : escStr(stringParking) },
			{ id : 2, name : escStr(stringToilettes) },
			{ id : 3, name : escStr(stringStation) },
			{ id : 4, name : escStr(stringSecurite) },
			{ id : 5, name : escStr(stringAdmin) },
			{ id : 6, name : escStr(stringTourisme) },
			{ id : 7, name : escStr(stringBorne) },
		];
		const filterType =
			<ListSelect
				handleSelect={this.handleSelect}
				listId="infos-type"
				listTitle={escStr(this.props.appData.tStrings.filtrer_categorie)}
				listItems={filterTypeValues}
				listSelectedId={this.state.selectedType}
			/>;


		///////////////////////////////////////
		// Build Infos List
		this.infos = [];
		for (let i=0; i<this.props.appData.infos.length; i++) {
			if (this.state.selectedType === 0) {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 1 && this.props.appData.infos[i].type === 'parking') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 2 && this.props.appData.infos[i].type === 'toilettes') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 3 && this.props.appData.infos[i].type === 'station') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 4 && this.props.appData.infos[i].type === 'securite') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 5 && this.props.appData.infos[i].type === 'administratif') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 6 && this.props.appData.infos[i].type === 'tourisme') {
				this.infos.push(this.props.appData.infos[i]);
			}
			else if (this.state.selectedType === 7 && this.props.appData.infos[i].type === 'borne') {
				this.infos.push(this.props.appData.infos[i]);
			}
		}


		///////////////////////////////////////
		// User position
		const mapUser = new L.Icon({
			iconUrl: mapImgUser,
			iconSize: [20, 20],
			iconAnchor: [10, 10]
		});
		let userMarker = "";
		if (this.state.geolocation === true && this.userLocation !== null) {
			userMarker = <Marker position={[this.userLocation.coords.latitude, this.userLocation.coords.longitude]} icon={mapUser}></Marker>;
		}


		///////////////////////////////////////
		// Map
		let infosMarkers = '';
		let infosMap = '';
		if (this.props.appData.infos.length > 0) {
			if (!isEmpty(this.infos)) {
				infosMarkers = this.infos.map( (info, count) => {
							let mapImg = mapParking;
							if (info.type === 'toilettes') { mapImg = mapToilettes; }
							if (info.type === 'station') { mapImg = mapEssence; }
							if (info.type === 'securite') { mapImg = mapSecurite; }
							if (info.type === 'administratif') { mapImg = mapAdmin; }
							if (info.type === 'tourisme') { mapImg = mapTourisme; }
							if (info.type === 'borne') { mapImg = mapBorne; }
							const mapMarker = new L.Icon({
								iconUrl: mapImg,
								iconSize: this.mapOptions.mapMarkerSize,
								iconAnchor: this.mapOptions.mapMarkerAnchor
							});
							const infoWindowClass = 'ww-eventsInfoWindow';
							const popup =
								<div className={infoWindowClass}>
									<p className="ww-eventsInfoWindow__title">{escStr(info.title)}</p>
									<p className="ww-eventsInfoWindow__description">{nl2br(escStr(info.description))}</p>
								</div>;
							return (<Marker key={count} position={[info.latitude, info.longitude]} icon={mapMarker}><Popup maxWidth={260} maxHeight={400}>{popup}</Popup></Marker>);
						});
			}
			infosMap =
					<div className="ww-infos__map">
						<MapContainer center={[48.854742, 2.347721]} zoom={15} zoomSnap={0.1}>
							<MapConsumer>{(map) => { this.map = map; return null; }}</MapConsumer>
							<TileLayer url={this.props.appData.mapProvider} opacity={this.mapOptions.mapOpacity} />
							{userMarker}
							{infosMarkers}
						</MapContainer>
					</div>;
		}
		else {
			infosMap = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
		}


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'infos') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}

		pageContent =
			<div className="page-content">
				<Navbar
					historyProps={this.props.historyProps}
					handleClickEvent={this.props.handleClickEvent}
					appOptions={this.props.appOptions}
					appData={this.props.appData}
					splashData={this.props.splashData}
				/>
				<h2 className="ww-boxList__title ww-marginBottom--none">{escStr(title)}</h2>
				{filterType}
				{infosMap}
				<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
					<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
				</div>
			</div>;


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Handle Select Date
	handleSelect(listId, value) {
		if (listId === "infos-type") {
			this.setState({ selectedType : value });
		}
	}

}

export default InfosScreen;
