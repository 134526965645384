// React
import React from 'react';

class Box extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	imageBlob = null;
	forceUpdateImage = false;


	///////////////////////////////////////
	// Constructor
	constructor() {
		super();

		// Init state
		this.state = {
			indexedDBCheck: false,
			imageUrl: ''
		};

		// Bind this to functions
		this.getImage = this.getImage.bind(this);

	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		this.getImage();
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {
		if (this.state.imageUrl.length > 0
			&& typeof(this.props.boxImage) !== 'undefined'
			&& this.props.boxImage.length > 0
			&& this.props.boxImageWidth > 0
			&& this.props.boxImageHeight > 0
			&& this.state.imageUrl !== this.props.boxImage) {
			this.getImage();
		}
	}


	///////////////////////////////////////
	// Render
	render() {

		const boxClass = (this.props.boxImageHeight === 0) ? 'ww-box ww-box__imageEmpty' : 'ww-box';
		const boxPropClass = this.props.boxClass ? this.props.boxClass : '';

		const imageClass = (this.props.boxImageHeight > 0 && ((this.props.boxImageWidth / this.props.boxImageHeight) > 1.6))
			? `ww-box__image ww-box__image--height` : `ww-box__image ww-box__image--width`;

		let image = "";
		if (typeof(this.props.boxImage) !== 'undefined'
			&& this.props.boxImage.length > 0
			&& this.props.boxImageWidth > 0
			&& this.props.boxImageHeight > 0) {
			if (this.state.indexedDBCheck === true && this.imageBlob !== null) {
				let urlCreator = window.URL || window.webkitURL;
				let imageUrl = urlCreator.createObjectURL(this.imageBlob);
				image = <img alt="" className={imageClass} src={imageUrl} />;
				//console.log("Image from IndexedDB");
			}
			else if (this.state.indexedDBCheck === true && this.imageBlob === null) {
				image = <img alt="" className={imageClass} src={this.props.boxImage} />;
				//console.log("Image from server");
			}
		}

		return(
			<div className={`${boxClass} ${boxPropClass}`} onClick={ () => { if (typeof(this.props.handleClickEvent) !== "undefined") { this.props.handleClickEvent(this.props.handleClickContext, this.props.handleClickValue, this.props.handleClickHistoryProps) } } }>
				{image}
				{this.props.boxContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Get Image
	getImage() {

		// Try to get image from indexedDB
		if (typeof(this.props.boxImage) !== 'undefined'
			&& this.props.boxImage.length > 0
			&& this.props.boxImageWidth > 0
			&& this.props.boxImageHeight > 0
			&& (this.state.indexedDBCheck === false || this.state.imageUrl !== this.props.boxImage)
			){
			this.imageBlob = null;
			var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
			if (indexedDB) {
				// DB Open and Create
				let dbStore = 'haropa';
				if (typeof(this.props.dbStore) !== 'undefined'
						&& this.props.dbStore.length > 0) {
					dbStore = this.props.dbStore;
				}
				var requestOpen = indexedDB.open(dbStore, 1);
				requestOpen.onerror = function(event){ this.setState({ indexedDBCheck : true }); };

				// DB Schema
				requestOpen.onupgradeneeded = function(event) {
					let db = event.target.result;
					let objectStore = db.createObjectStore(dbStore, { keyPath: 'id', autoIncrement: true });
					objectStore.createIndex('url', 'url', { unique: true });
					objectStore.createIndex('data', 'data', { unique: false });
					objectStore.createIndex('type', 'type', { unique: false });
				}

				// DB Open Success
				requestOpen.onsuccess = (function(event){
					let db = event.target.result;
					if (db.objectStoreNames.length === 0) {
						//console.log("Image URL NOT found in IndexedDB", event);
						if (this.mounted === true) {
							this.setState({
								indexedDBCheck : true,
								imageUrl : this.props.boxImage,
							});
						}
					}
					else {
						let transaction = db.transaction([dbStore]);
						let objectStore = transaction.objectStore(dbStore).index("url");

						// Search for Image URL in IndexedDB
						let requestGet = objectStore.get(this.props.boxImage);
						requestGet.onerror = (function(event){
							//console.log("Image URL NOT found in IndexedDB", event);
							if (this.mounted === true) {
								this.setState({
									indexedDBCheck : true,
									imageUrl : this.props.boxImage,
								});
							}
						}).bind(this);
						requestGet.onsuccess = (function(event){
							if (typeof(event.target.result) !== "undefined") {
								//console.log("Image URL found in IndexedDB", event.target.result);
								if (this.mounted === true) {
									this.imageBlob = new Blob([event.target.result.data], { type : event.target.result.type });
									this.setState({
										indexedDBCheck : true,
										imageUrl : this.props.boxImage,
									});
								}
							}
							else {
								//console.log("Image URL NOT found in IndexedDB", event);
								if (this.mounted === true) {
									this.setState({
										indexedDBCheck : true,
										imageUrl : this.props.boxImage,
									});
								}
							}
						}).bind(this);
					}
				}).bind(this);

			}
			else {
				if (this.mounted === true) {
					this.setState({
						indexedDBCheck : true,
						imageUrl : this.props.boxImage,
					});
				}
			}
		}
		else {
			if (this.mounted === true) {
				this.setState({
					indexedDBCheck : true,
					imageUrl : this.props.boxImage,
				});
			}
		}
	}

}

export default Box;
