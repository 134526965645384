// React
import React from 'react';

// Tools / Library
import {escStr, xyz, fetchWithTimeout} from '../Utils'
import {MapContainer, MapConsumer, Marker, Polyline} from 'react-leaflet';
import L from 'leaflet';
import ReactNoSleep from 'react-no-sleep';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {BiTargetLock, BiPlay, BiPause, BiVolumeMute} from 'react-icons/bi';
import {FaMapMarkedAlt} from 'react-icons/fa';
import {IoLocationSharp} from 'react-icons/io5';
import {AiFillStar, AiOutlineStar} from 'react-icons/ai';
import {indexOf} from 'lodash';

// Components
import Navbar from './Navbar';
import Box from './Box';
import UnknownScreen from './UnknownScreen';

// Ressources
import smapImgMarker from '../Images/map-marker.svg';
import smapImgStart from '../Images/map-start.svg';
import smapImgEnd from '../Images/map-end.svg';
import smapImgUser from '../Images/map-user.svg';
import smapImgHeading from '../Images/map-heading.svg';
import emptyTile from '../Images/tile.png';
import '../Css/leaflet.css';

const nl2br = require('react-nl2br');


class VisitView extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	geoLocationAvailable = false;
	visit = null;
	ressourceFilesUrl = [];
	ressourceFilesObject = [];
	markers = [];
	markersFlag = [];
	etapes = [];
	etapesMarkerIndex = [];
	points = [];
	map = null;
	tileLayer = null;
	audioUrl = "";
	audioElement = null;
	watchPositionId = null;
	moveToTop = false;
	noSleepIsOn = false;
	noSleepEnable = null;
	noSleepDisable = null;
	playPromise = null;
	headingMarker = null;
	clickOnPlay = false;
	rateSend = false;
	userDistance = 0;
	userMessage = '';
	watchdogTimeout = null;
	mapOptions = {
		mapOpacity: 0.6,
		mapMarkerSize: [28, 40],
		mapMarkerAnchor: [14, 40],
		mapLineColor: "#0037ff",
		mapLineOpacity: 0.6,
		mapLineWidth: 5,
		mapLineDash: [],
	};


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init Visit
		for (let i=0; i<props.appData.visites.length; i++) {
			if (props.appData.visites[i].id === parseInt(props.visitId, 10)) {
				this.visit = props.appData.visites[i];
				break;
			}
		}

		// Init Markers
		if (this.visit !== null) {
			let lastLat = 0
			let lastLng = 0
			for (let i=0; i<this.visit.points.length; i++) {
				let tPoint = this.visit.points[i];
				if (tPoint.type !== "point") {
					if (lastLat !== tPoint.latitude || lastLng !== tPoint.longitude) {
						this.markers.push([tPoint.latitude, tPoint.longitude]);
						this.markersFlag.push(false);
						lastLat = tPoint.latitude;
						lastLng = tPoint.longitude;
					}
					this.etapes.push(tPoint);
					if (this.markers.length > 0) { this.etapesMarkerIndex.push(this.markers.length - 1); }
					else { this.etapesMarkerIndex.push(0); }
				}
				this.points.push([tPoint.latitude, tPoint.longitude]);
			}

			// Ressources Files
			this.ressourceFilesUrl = [];
			this.ressourceFilesObject = [];
			// Audio
			for (let i=0; i<this.visit.points.length; i++) {
				if (this.visit.points[i].type === "etape"
					&& typeof(this.visit.points[i].audio_file) !== "undefined"
					&& this.visit.points[i].audio_file.length > 0) {
					if (indexOf(this.ressourceFilesUrl, this.visit.points[i].audio_file) === -1) {
						this.ressourceFilesUrl.push(this.visit.points[i].audio_file);
						this.ressourceFilesObject.push({ originUrl : this.visit.points[i].audio_file, finalUrl : this.visit.points[i].audio_file});
					}
				}
			}
			// Map points
			let minLat = 1000000000;
			let maxLat = -1000000000;
			let minLng = 1000000000;
			let maxLng = -1000000000;
			for (let i=0; i<this.visit.points.length; i++) {
				if (this.visit.points[i].latitude < minLat) { minLat = this.visit.points[i].latitude ;}
				if (this.visit.points[i].latitude > maxLat) { maxLat = this.visit.points[i].latitude ;}
				if (this.visit.points[i].longitude < minLng) { minLng = this.visit.points[i].longitude ;}
				if (this.visit.points[i].longitude > maxLng) { maxLng = this.visit.points[i].longitude ;}
			}
			let bounds = [[minLng, minLat],[maxLng, maxLat]];
			let tiles = xyz(bounds, this.visit.point_zoom);
			//console.log("tiles", tiles);
			for (let i=0; i<tiles.length; i++) {
				let server = 'a.';
				let tileUrl = this.props.appData.mapProvider.replace(/{s}\./g, server);
				tileUrl = tileUrl.replace(/{x}/g, tiles[i].x);
				tileUrl = tileUrl.replace(/{y}/g, tiles[i].y);
				tileUrl = tileUrl.replace(/{z}/g, tiles[i].z);
				tileUrl = tileUrl.replace(/{r}/g, '@2x');
				//console.log("tileUrl", tileUrl);
				if (indexOf(this.ressourceFilesUrl, tileUrl) === -1) {
					this.ressourceFilesUrl.push(tileUrl);
					this.ressourceFilesObject.push({ originUrl : tileUrl, finalUrl : tileUrl});
				}
			}
		}
		//console.log("Markers", this.markers);
		//console.log("Markers Flag", this.markersFlag);
		//console.log("Etapes", this.etapes);
		//console.log("Etapes Index", this.etapesMarkerIndex);
		//console.log("ressourceFilesObject", this.ressourceFilesObject);


		// Init Etape Index
		let initEtapeIndex = 0;
		if (typeof(Storage) !== "undefined") {
			let savedId = localStorage.getItem('visits_selectedId');
			let savedEtape = localStorage.getItem('visits_selectedEtape');
			if (typeof(savedEtape) !== "undefined"
				&& savedEtape !== null
				&& parseInt(savedEtape, 10) >= 0 && parseInt(savedEtape, 10) < this.etapes.length
				&& typeof(savedId) !== "undefined"
				&& savedId !== null
				&& savedId === this.visit.id) {
				initEtapeIndex = parseInt(savedEtape, 10);
			}
		}

		// Init State
		this.state = {
			userLocation: null,
			etapeIndex: initEtapeIndex,
			centerMode: 2,
			audioPlaying: false,
			audioUpdated: false,
			displayRater: false,
			rateValue: 0,
		}

		// Bind this to functions
		this.handleRate = this.handleRate.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSound = this.handleSound.bind(this);
		this.handleGeolocation = this.handleGeolocation.bind(this);
		this.ressourceFilesBuild = this.ressourceFilesBuild.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;

		if (this.visit !== null) {

			// Build ressources files
			this.ressourceFilesBuild();
			//console.log(this.ressourceFilesObject);

			setTimeout( () => {

				///////////////////////////////////////

				// Custom Tile Layer
				L.TileLayer.WW = L.TileLayer.extend({
					getTileUrl: (coords) => {
						let server = 'a.';
						let tileUrl = this.props.appData.mapProvider.replace(/{s}\./g, server);
						tileUrl = tileUrl.replace(/{x}/g, coords.x);
						tileUrl = tileUrl.replace(/{y}/g, coords.y);
						tileUrl = tileUrl.replace(/{z}/g, coords.z);
						tileUrl = tileUrl.replace(/{r}/g, '@2x');
						//console.log(tileUrl);
						for (let i=0; i<this.ressourceFilesObject.length; i++) {
							if (this.ressourceFilesObject[i].originUrl === tileUrl) {
								return this.ressourceFilesObject[i].finalUrl;
							}
						}
						return emptyTile;
					}
				});

				// Check if we are online
				if (window.navigator.onLine) {
					// We add the standard tile layer
					this.tileLayer = new L.TileLayer(this.props.appData.mapProvider);
				}
				else {
					// We add the custom tile layer and disable the zoom
					this.tileLayer = new L.TileLayer.WW();
					this.map.touchZoom.disable();
					this.map.doubleClickZoom.disable();
					this.map.scrollWheelZoom.disable();
				}
				this.tileLayer.addTo(this.map);
				//console.log(this.tileLayer);


				///////////////////////////////////////
				// Init Audio element
				this.audioElement = new Audio();
				if (typeof(this.audioElement) !== "undefined" && this.audioElement !== null) {
					this.audioElement.onended = () => this.handleSound("ended");
					if (this.etapes[this.state.etapeIndex].audio_file.length > 0) {
						// Look for Audio file in Indexed DB
						let fileIndex = indexOf(this.ressourceFilesUrl, this.etapes[this.state.etapeIndex].audio_file);
						if (fileIndex !== -1) { this.audioUrl = this.ressourceFilesObject[fileIndex].finalUrl; }
						else { this.audioUrl = this.etapes[this.state.etapeIndex].audio_file; }
						this.audioElement.src = this.audioUrl;
						//console.log('audio file', this.audioUrl);
					}
					else { this.audioUrl = ""; }
				}

				///////////////////////////////////////
				// Init Geolocation
				this.geoLocationAvailable = false;
				if ('geolocation' in navigator) {
					navigator.geolocation.getCurrentPosition(
						location => this.handleGeolocation(location, "init"),
						positionError => this.handleGeolocation(positionError, "error"),
						{ enableHighAccuracy: true, timeout: 60000, maximumAge: 0 }
					);
				}

			}, 500);
		}

		window.scrollTo(0, 0);
		this.moveToTop = false;

	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {

		if (this.visit !== null) {

			// Audio management
			if (this.state.audioUpdated) {
				this.audioElement.pause();
				if (this.audioUrl.length > 0
					&& this.state.audioPlaying === true
					&& typeof(this.audioElement) !== "undefined"
					&& this.audioElement) {
					this.playPromise = this.audioElement.play();
					if (this.playPromise !== undefined) { this.playPromise.then(_ => { }).catch(error => { }); }
				}
			}

			// Update Map Position
			if (this.map !== null) {
				if (this.state.centerMode === 2 || this.state.userLocation === null) {
					this.map.setView(this.markers[this.etapesMarkerIndex[this.state.etapeIndex]], this.visit.point_zoom);
				}
				else if (this.state.userLocation !== null && this.state.centerMode === 1) {
					this.map.setView([this.state.userLocation.coords.latitude, this.state.userLocation.coords.longitude], this.visit.point_zoom);
				}
				else if (this.state.centerMode === 0) {
					// Zoom only if online
					if (window.navigator.onLine) { this.map.setView([this.visit.center_latitude, this.visit.center_longitude], this.visit.center_zoom); }
					else { this.map.setView([this.visit.center_latitude, this.visit.center_longitude], this.visit.point_zoom); }
				}
			}

		}

		if (this.moveToTop === true) { window.scrollTo(0, 0); }
		this.moveToTop = false;

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;

		if (typeof(this.props.historyHandler) !== "undefined") {
			this.props.historyHandler(this.props.historyProps);
		}

		// Cancel Geolocation Watchdog
		if (this.geoLocationAvailable === true) {
			navigator.geolocation.clearWatch(this.watchPositionId);
		}
		if (this.watchdogTimeout) { clearTimeout(this.watchdogTimeout); }

		// Stop audio
		if (typeof(this.audioElement) !== "undefined" && this.audioElement !== null) {
			this.audioElement.pause();;
		}

		// Disable NoSleep
		if (this.noSleepDisable !== null
			&& typeof(this.noSleepDisable) === "function"
			&& this.noSleepIsOn === true) {
			this.noSleepDisable();
		}

	}


	///////////////////////////////////////
	// Render
	render() {

		///////////////////////////////////////
		// Watchdog for geolocation
		this.positionWatchdogSet();


		/* ////////////////////////////////////////////////////////////////////////////
		// Rate Visit
		//////////////////////////////////////////////////////////////////////////// */
		let rateDialog = "";
		if (this.state.displayRater === true) {
			const star1 = (this.state.rateValue >= 1) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
			const star2 = (this.state.rateValue >= 2) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
			const star3 = (this.state.rateValue >= 3) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
			const star4 = (this.state.rateValue >= 4) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
			const star5 = (this.state.rateValue >= 5) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;

			let rateContent =
				<div className="ww-visit__rate">
					<h2 className="ww-boxList__title">{this.props.appData.tStrings.visite_terminee}</h2>
					<p>{this.props.appData.tStrings.visite_note}</p>
					<ul>
						<li onClick={ () => this.handleRate(1) }>{star1}</li>
						<li onClick={ () => this.handleRate(2) }>{star2}</li>
						<li onClick={ () => this.handleRate(3) }>{star3}</li>
						<li onClick={ () => this.handleRate(4) }>{star4}</li>
						<li onClick={ () => this.handleRate(5) }>{star5}</li>
					</ul>
				</div>;

			let closeDisabled = (this.state.rateValue === 0) ? true : false;
			let buttonClass = "ww-dialog__close ww-button--primary";
			if (closeDisabled === true) { buttonClass = "ww-dialog__close ww-button--primary ww-dialog--disabled"; }

			rateDialog =
				<div className="ww-dialog">
					<div className="ww-dialog__container">
						<div className="ww-dialog__content">{rateContent}</div>
						<div className={buttonClass} onClick={ () => {
							if (closeDisabled === true) { return; }
							else {
								const formData = new FormData();
								formData.append('apikey', this.props.appOptions.backofficeAPIKey);
								formData.append('version', this.props.appOptions.version);
								formData.append('language', this.props.splashData.code);
								formData.append('order', 'visite_note');
								formData.append('visit_id', this.visit.id);
								formData.append('note', this.state.rateValue);
								const fetchParams = {
									method: 'POST',
									body: formData,
								};
								fetchWithTimeout(this.props.appOptions.backofficeSubmitUrl, fetchParams, 10000)
									.then(response => response.json())
									.then(result => {});

								if (typeof(Storage) !== "undefined") { localStorage.removeItem('visits_selectedEtape'); }
								this.props.handleClickEvent("generic_back", null, this.props.historyProps);
							}}}>{this.props.appData.tStrings.terminer}</div>
					</div>
				</div>;
		}


		/* ////////////////////////////////////////////////////////////////////////////
		// Map
		//////////////////////////////////////////////////////////////////////////// */

		///////////////////////////////////////
		// User position
		const mapUser = new L.Icon({
			iconUrl: smapImgUser,
			iconSize: [20, 20],
			iconAnchor: [10, 10]
		});
		let userMarker = "";
		let nextStep = "";
		if (this.geoLocationAvailable === true && this.state.userLocation !== null) {
			userMarker = <Marker position={[this.state.userLocation.coords.latitude, this.state.userLocation.coords.longitude]} icon={mapUser}></Marker>;
			/*if (this.etapesMarkerIndex[this.state.etapeIndex] < this.markers.length-1) {
				nextStep = <p id="ww-visit__nextStep"><IoLocationSharp size="14px" />{escStr(this.props.appData.tStrings.vous_etes_a) + ' ' + processGeolocationDistance(this.state.userLocation.coords.latitude, this.state.userLocation.coords.longitude, this.markers[this.etapesMarkerIndex[this.state.etapeIndex] + 1][0], this.markers[this.etapesMarkerIndex[this.state.etapeIndex] + 1][1], true) + ' ' + escStr(this.props.appData.tStrings.de_la_prochaine_etape)}</p>;
			}*/
		}


		///////////////////////////////////////
		// Back button
		const backButton = <div className="ww-visit__mapBackButton" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }><MdKeyboardArrowLeft size="30px" /></div>;


		///////////////////////////////////////
		// Map Center button
		let centerButton = "";
		// Visite center
		if (this.state.centerMode === 0) {
			centerButton = <div className="ww-visit__mapCenterButton" onClick={ () => this.setState({ centerMode : 1 }) }><FaMapMarkedAlt size="18px" /></div>;
		}
		// User center
		if (this.state.centerMode === 1) {
			centerButton = <div className="ww-visit__mapCenterButton" onClick={ () => this.setState({ centerMode : 2 }) }><BiTargetLock size="26px" /></div>;
		}
		// Marker center
		if (this.state.centerMode === 2) {
			centerButton = <div className="ww-visit__mapCenterButton" onClick={ () => this.setState({ centerMode : 0 }) }><IoLocationSharp size="26px" /></div>;
		}


		let debugPosition = "";
		/*if (this.geoLocationAvailable === true && this.state.userLocation !== null) {
			debugPosition =
				<div className="ww-visit__mapDebug">
					Latitude : {this.state.userLocation.coords.latitude}<br />
					Longitude : {this.state.userLocation.coords.longitude}<br />
					Précision : {Math.round(this.state.userLocation.coords.accuracy)}m<br />
					Timestamp : {this.state.userLocation.timestamp}<br />
					Index Etape : {this.state.etapeIndex}<br />
					User Distance : {this.userDistance}<br />
					User Message :<br />{this.userMessage}
				</div>;
		}*/


		///////////////////////////////////////
		// Map
		const mapMarker = new L.Icon({
			iconUrl: smapImgMarker,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});
		const mapStart = new L.Icon({
			iconUrl: smapImgStart,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});
		const mapEnd = new L.Icon({
			iconUrl: smapImgEnd,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});

		// Map
		let visitMap = "";
		if (this.visit !== null) {
			visitMap =
				<div className="ww-visit__map">
					{nextStep}
					<MapContainer center={this.markers[0]} zoom={this.visit.point_zoom} zoomSnap={0.1}>
						<MapConsumer>{(map) => { this.map = map; return null; }}</MapConsumer>
						{userMarker}
						{this.markers.map( (point, count) => {
							if (count === this.etapesMarkerIndex[this.state.etapeIndex]) {
								if (count === 0) { return(<Marker key={count} position={point} icon={mapStart}></Marker>); }
								else if (count === this.markers.length - 1) { return(<Marker key={count} position={point} icon={mapEnd}></Marker>); }
								else { return(<Marker key={count} position={point} icon={mapMarker}></Marker>); }
							}
							else { return(''); }
						})}
						<Polyline color={this.mapOptions.mapLineColor} dashArray={this.mapOptions.mapLineDash} weight={this.mapOptions.mapLineWidth} positions={this.points} opacity={this.mapOptions.mapLineOpacity} />
					</MapContainer>
					{centerButton}
					{backButton}
					{debugPosition}
				</div>;
		}


		/* ////////////////////////////////////////////////////////////////////////////
		// Navigation Bar & Audio
		//////////////////////////////////////////////////////////////////////////// */

		// Audio file
		if (this.visit !== null) {
			if (typeof(this.audioElement) !== "undefined" && this.audioElement !== null) {
				/*if (this.state.audioPlaying === false
						|| this.audioUrl !== this.etapes[this.state.etapeIndex].audio_file) {
					this.audioElement.pause();
				}*/
				if (this.audioElement.src !== this.etapes[this.state.etapeIndex].audio_file) {
					if (this.etapes[this.state.etapeIndex].audio_file.length > 0) {
						// Look for Audio file in Indexed DB
						let fileIndex = indexOf(this.ressourceFilesUrl, this.etapes[this.state.etapeIndex].audio_file);
						if (fileIndex !== -1) { this.audioUrl = this.ressourceFilesObject[fileIndex].finalUrl; }
						else { this.audioUrl = this.etapes[this.state.etapeIndex].audio_file; }
					}
					else { this.audioUrl = ""; }
					this.audioElement.src = this.audioUrl;
					//console.log('audio file', this.audioUrl);
				}
			}
		}

		// Audio Buttons
		let audioButton = "";
		if (this.visit !== null) {
			if (this.state.etapeIndex >= 0
				&& this.etapes[this.state.etapeIndex].audio_file.length > 0
				&& this.state.audioPlaying === false) {
				audioButton = <div className="ww-visit__navButton__audio ww-visit__navButton__audio--play" onClick={ () => this.handleClick("audio") }><BiPlay size="30px" /></div>;
			}
			else if (this.state.etapeIndex >= 0
				&& this.etapes[this.state.etapeIndex].audio_file.length > 0
				&& this.state.audioPlaying === true) {
				audioButton = <div className="ww-visit__navButton__audio ww-visit__navButton__audio--pause" onClick={ () => this.handleClick("audio") }><BiPause size="30px" /></div>;
			}
			else if (this.state.etapeIndex >= 0
				&& this.etapes[this.state.etapeIndex].audio_file.length === 0) {
				audioButton = <div className="ww-visit__navButton__audio ww-visit__navButton__audio--mute"><BiVolumeMute size="30px" /></div>;
			}
		}

		// Navigation Buttons
		let navButtons = "";
		if (this.visit !== null) {
			if (this.state.etapeIndex >= 0 && this.state.etapeIndex < this.etapes.length - 1) {
				navButtons =
					<ul className="ww-visit__navButton__buttons">
						<li className="ww-visit__navButton__buttons--prev" onClick={ () => this.handleClick("prev") }><MdKeyboardArrowLeft size="26px" />{this.props.appData.tStrings.precedent}</li>
						<li className="ww-visit__navButton__buttons--next" onClick={ () => this.handleClick("next") }><MdKeyboardArrowRight size="26px" />{this.props.appData.tStrings.suivant}</li>
					</ul>;
			}
			else if (this.state.etapeIndex === this.etapes.length - 1) {
				navButtons =
					<ul className="ww-visit__navButton__buttons">
						<li className="ww-visit__navButton__buttons--prev" onClick={ () => this.handleClick("prev") }><MdKeyboardArrowLeft size="26px" />{this.props.appData.tStrings.precedent}</li>
						<li className="ww-visit__navButton__buttons--next" onClick={ () => this.setState({ displayRater : true }) }><MdKeyboardArrowRight size="26px" />{this.props.appData.tStrings.terminer}</li>
					</ul>;
			}
		}

		// Navigation Bar
		let navigationBar = "";
		if (this.visit !== null) {
			navigationBar =
				<div className="ww-visit__navButtons">
					{navButtons}
					{audioButton}
				</div>;
		}


		/* ////////////////////////////////////////////////////////////////////////////
		// Image / Text Content
		//////////////////////////////////////////////////////////////////////////// */

		let pageContent = "";
		let pageClass = "";
		if (this.visit !== null) {
			pageClass = "page-visitViewer";

			let image = "";
			if (typeof(this.etapes[this.state.etapeIndex].image) !== "undefined"
				&& this.etapes[this.state.etapeIndex].image.length > 0
				&& this.etapes[this.state.etapeIndex].image_width > 0
				&& this.etapes[this.state.etapeIndex].image_height > 0) {
				image =
					<Box
						boxClass="ww-box--visitPoint"
						boxImage={this.etapes[this.state.etapeIndex].image}
						boxImageWidth={this.etapes[this.state.etapeIndex].image_width}
						boxImageHeight={this.etapes[this.state.etapeIndex].image_height}
						dbStore="haropavisit"
					/>;
			}

			let adresse = '';
			if (this.etapes[this.state.etapeIndex].adresse.length > 0) {
				adresse = <p className="ww-visit__adresse">{nl2br(escStr(this.etapes[this.state.etapeIndex].adresse))}</p>
			}

			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{visitMap}
					<div className="ww-visit__content ww-visit__content--point">
						{image}
						<h3 style={{ textAlign : "center", marginBottom : "18px" }}>{this.state.etapeIndex+1}/{this.etapes.length}</h3>
						{adresse}
						<p className="ww-visit__description">{nl2br(escStr(this.etapes[this.state.etapeIndex].description))}</p>
					</div>
					{navigationBar}
					<ReactNoSleep>{({ isOn, enable, disable }) => {
							this.noSleepIsOn = isOn;
							this.noSleepEnable = enable;
							this.noSleepDisable = disable;
							return("");
						}}</ReactNoSleep>
				</div>;
		}

		// Unknown Screen
		else {
			pageClass = "page-pageViewer page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}

		return(
			<div className={pageClass}>
				{pageContent}
				{rateDialog}
			</div>
		);

	}

	///////////////////////////////////////
	// Handle Click Event
	handleClick(type) {

		let newIndex = 0;
		let newPlaying = false;
		let audioUpdated = false;

		switch (type) {

			case "prev":
				this.moveToTop = true;

				newIndex = this.state.etapeIndex - 1;
				if (newIndex < 0) { newIndex = 0; }
				if (typeof(Storage) !== "undefined") {
					localStorage.setItem('visits_selectedId', this.visit.id);
					localStorage.setItem('visits_selectedEtape', newIndex);
				}

				if (newIndex !== this.state.etapeIndex) {
					if (this.etapes[newIndex].audio_file.length > 0) { newPlaying = this.clickOnPlay; }
					else { newPlaying = false; }
					audioUpdated = true;
				}

				//this.markersFlag[this.etapesMarkerIndex[newIndex]] = true;

				this.setState({
					audioPlaying: newPlaying,
					etapeIndex: newIndex,
					audioUpdated: audioUpdated
				});
				break;


			case "next":
				// Activate NoSleep if necessary
				if (this.noSleepEnable !== null
					&& typeof(this.noSleepEnable) === "function"
					&& this.noSleepIsOn === false){ this.noSleepEnable(); }
				this.moveToTop = true;

				newIndex = this.state.etapeIndex + 1;
				if (newIndex === this.etapes.length) { newIndex = this.etapes.length - 1; }
				if (typeof(Storage) !== "undefined") {
					localStorage.setItem('visits_selectedId', this.visit.id);
					localStorage.setItem('visits_selectedEtape', newIndex);
				}

				if (newIndex !== this.state.etapeIndex) {
					if (this.etapes[newIndex].audio_file.length > 0) { newPlaying = this.clickOnPlay; }
					else { newPlaying = false; }
					audioUpdated = true;
				}

				//this.markersFlag[this.etapesMarkerIndex[newIndex]] = true;

				this.setState({
					audioPlaying: newPlaying,
					etapeIndex: newIndex,
					audioUpdated: audioUpdated
				});
				break;


			case "audio":
				// Activate NoSleep if necessary
				if (this.noSleepEnable !== null
					&& typeof(this.noSleepEnable) === "function"
					&& this.noSleepIsOn === false) { this.noSleepEnable(); }
				this.clickOnPlay = true;
				audioUpdated = true;
				this.setState({
					audioPlaying: !this.state.audioPlaying,
					audioUpdated: audioUpdated
				});
				break;

			default:
				break;
		}
	}


	///////////////////////////////////////
	// Handle Sound Event
	handleSound(type) {
		if (type === "ended") { this.setState( {audioPlaying: false} ); }
	}


	///////////////////////////////////////
	// Handle Rate
	handleRate(value) {
		this.setState( { rateValue : value} );
	}


	///////////////////////////////////////
	// Handle Geolocalisation
	handleGeolocation(location, type) {

		if (type === "init") {
			this.geoLocationAvailable = true;
			this.setState({ userLocation : location });
			this.watchPositionId = navigator.geolocation.watchPosition(
				location => this.handleGeolocation(location, "update"),
				positionError => this.handleGeolocation(positionError, "error"),
				{ enableHighAccuracy: true, timeout: 0, maximumAge: 0 }
			);
		}


		if (type === "init" || type === "update") {
			if (typeof(location.coords) !== "undefined"
					&& location.coords.latitude !== 0
					&& location.coords.longitude !== 0
					&& (location.coords.accuracy < 200 || type === "init")) {

				// Heading
				if (this.map !== null && this.headingMarker === null) {
					const userDirectionIcon = new L.Icon({
						iconUrl: smapImgHeading,
						iconSize: [32, 26],
						iconAnchor: [16, 26]
					});
					this.headingMarker = L.marker([location.coords.latitude, location.coords.longitude], {icon: userDirectionIcon}).addTo(this.map).setZIndexOffset(-1);;
				}
				if (this.map !== null && this.headingMarker !== null) {
					this.headingMarker.setLatLng([location.coords.latitude, location.coords.longitude]);
					if (typeof(location.coords.heading) !== "undefined"
						&& location.coords.heading !== null
						&& typeof(this.headingMarker._icon.style[L.DomUtil.TRANSFORM]) !== "undefined") {
						this.headingMarker._icon.style[L.DomUtil.TRANSFORM] += 'rotate(' + Math.round(location.coords.heading) + 'deg)';
						this.headingMarker._icon.style[L.DomUtil.TRANSFORM+'Origin'] = 'bottom center';
					}
				}


				///////////////////////////////////////
				// Auto detect etape
				let newEtapeIndex = this.state.etapeIndex;
				let newPlaying = this.state.audioPlaying;
				let audioUpdated = false;
				this.userDistance = 10000000;

				// Process distance
				let minMarkerIndex = -1;
				for (let i=0; i<this.markers.length; i++) {
					let distance = Math.floor(this.map.distance(this.markers[i], [location.coords.latitude, location.coords.longitude]));
					if (distance < this.userDistance) {
						this.userDistance = distance;
						minMarkerIndex = i;
					}
				}

				// If distance < 20 m and accuracy < 200m => auto set marker
				if (this.userDistance < 20 && location.coords.accuracy < 200) {
					for (let i=0; i<this.etapes.length; i++) {
						if (this.etapesMarkerIndex[i] === minMarkerIndex) {
							newEtapeIndex = i;
							break;
						}
					}

					// Check if new Marker is different than current one and has not already been activated
					if (this.markersFlag[minMarkerIndex] === false
						&& this.etapesMarkerIndex[this.state.etapeIndex] !== minMarkerIndex) {
						if (this.etapes[newEtapeIndex].audio_file.length > 0) { newPlaying = this.clickOnPlay; }
						else { newPlaying = false; }
						audioUpdated = true;
						this.userMessage = 'new detected and valid';
						this.markersFlag[minMarkerIndex] = true;
					}
					else {
						newEtapeIndex = this.state.etapeIndex;
						this.userMessage = 'new detected but not valid';
					}
				}
				else {
					this.userMessage = 'new not detected';
				}

				if (typeof(Storage) !== "undefined") {
					localStorage.setItem('visits_selectedId', this.visit.id);
					localStorage.setItem('visits_selectedEtape', newEtapeIndex);
				}
				this.setState({
					userLocation : location,
					etapeIndex : newEtapeIndex,
					audioPlaying : newPlaying,
					audioUpdated : audioUpdated,
				});
			}
		}

	}

	///////////////////////////////////////
	// Build Ressource Files Array
	ressourceFilesBuild() {

		var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		if (indexedDB) {
			if (this.ressourceFilesObject.length > 0) {
				for (let i=0; i<this.ressourceFilesObject.length; i++) {
					// DB Open and Create
					let dbStore = 'haropavisit';
					var requestOpen = indexedDB.open(dbStore, 1);
					requestOpen.onerror = function(event){
						//console.log("Create/Open Error");
					};

					// DB Schema
					requestOpen.onupgradeneeded = function(event) {
						//console.log("IndexedDB OnUpgradeNeeded");
						let db = event.target.result;
						let objectStore = db.createObjectStore(dbStore, { keyPath: 'id', autoIncrement: true });
						objectStore.createIndex('url', 'url', { unique: true });
						objectStore.createIndex('data', 'data', { unique: false });
						objectStore.createIndex('type', 'type', { unique: false });
					}

					// DB Open Success
					requestOpen.onsuccess = (function(event){
						//console.log("IndexedDB Create/Open Success");
						let db = event.target.result;
						if (db.objectStoreNames.length !== 0) {
							let transaction = db.transaction([dbStore]);
							let objectStore = transaction.objectStore(dbStore).index("url");

							// Search for File URL in IndexedDB
							let fileBlob = null;
							let requestGet = objectStore.get(this.ressourceFilesObject[i].originUrl);
							requestGet.onerror = (function(event){
								//console.log("IndexedDB Request Get Error");
							});
							requestGet.onsuccess = (function(event){
								//console.log("IndexedDB Request Get Success");
								if (typeof(event.target.result) !== "undefined") {
									if (this.mounted === true) {
										fileBlob = new Blob([event.target.result.data], { type : event.target.result.type });
										let urlCreator = window.URL || window.webkitURL;
										this.ressourceFilesObject[i].finalUrl = urlCreator.createObjectURL(fileBlob);
									}
								}
							}).bind(this);
						}
					}).bind(this);
				}
			}
		}

	}

	///////////////////////////////////////
	// Function Position Watchdog Set
	positionWatchdogSet() {
		if (this.watchdogTimeout !== null) { clearTimeout(this.watchdogTimeout); }
		this.watchdogTimeout = setTimeout( () => {
			this.positionWatchdogProcess();
			this.positionWatchdogSet();
		}, 30000);
	}

	///////////////////////////////////////
	// Function Position Watchdog Process
	positionWatchdogProcess() {
		if (this.geoLocationAvailable === true
			&& this.state.userLocation !== null
			&& Date.now() - this.state.userLocation.timestamp > 65 * 1000
			) {
			// Try to relauch Watch Position
			navigator.geolocation.clearWatch(this.watchPositionId);
			navigator.geolocation.getCurrentPosition(
				location => this.handleGeolocation(location, "init"),
				positionError => this.handleGeolocation(positionError, "error"),
				{ enableHighAccuracy: true, timeout: 60000, maximumAge: 0 }
			);
		}
	}

}

export default VisitView;
