// React
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// Tools / Library
import {analytics_push} from './Utils'

// Components
import UnknownScreen from './Components/UnknownScreen';
import HelpScreen from './Components/HelpScreen';
import SplashScreen from './Components/SplashScreen';
import DispatchScreen from './Components/DispatchScreen';
import CategoryScreen from './Components/CategoryScreen';
import PostViewer from './Components/PostViewer';
import PartenairesScreen from './Components/PartenairesScreen';
import PartenaireViewer from './Components/PartenaireViewer';
import VisitsDuree from './Components/VisitsDuree';
import VisitsScreen from './Components/VisitsScreen';
import VisitPreview from './Components/VisitPreview';
import VisitViewer from './Components/VisitViewer';
import EscalesScreen from './Components/EscalesScreen';
import EscaleViewer from './Components/EscaleViewer';
import EventsScreen from './Components/EventsScreen';
import SignalementsScreen from './Components/SignalementsScreen';
import InfosScreen from './Components/InfosScreen';

// Ressources
import './Css/styles.css';


class App extends React.Component {

	///////////////////////////////////////
	// Variables
	appOptions = {
		version: 1,
		build: '044',
		basePath: '/',
		backofficeUrl: 'https://bo.haropaport-paris-webapp.com/api.php',
		backofficeSubmitUrl: 'https://bo.haropaport-paris-webapp.com/submit.php',
		backofficeSignalementImageUploadUrl: 'https://bo.haropaport-paris-webapp.com/signalement_image_upload.php',
		backofficeAPIKey: 'a8D40gjRoP41v23q',
		primaryColor: '#0037ff',
		secondaryColor: '#32e169',
	};
	localStorageDataInit = false
	localStorageDataAvailable = false
	redirectToString = '';
	splashData = [];
	splashDataMD5 = '';
	selectedLanguageIndex = -1;
	appData = {};
	appDataMD5 = '';


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Bind this to functions
		this.handleClickEvent = this.handleClickEvent.bind(this);
		this.setSplashData = this.setSplashData.bind(this);
		this.setAppData = this.setAppData.bind(this);
		this.redirectTo = this.redirectTo.bind(this);
		this.checkLocalStorageData = this.checkLocalStorageData.bind(this);
	}


	///////////////////////////////////////
	// Render
	render() {
		return(
			<Router>
				<Switch>

					<Route exact path={this.appOptions.basePath} render={(props) => {
						analytics_push('splash', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return (
								<SplashScreen
									historyProps={props}
									appOptions={this.appOptions}
									splashData={this.splashData}
									splashDataMD5={this.splashDataMD5}
									setSplashData={this.setSplashData}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'dispatch'} render={(props) => {
						analytics_push('dispatch', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<DispatchScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									splashData={this.splashData[this.selectedLanguageIndex]}
									setAppData={this.setAppData}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "category/:id"} render={(props) => {
						analytics_push('categorie', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<CategoryScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									categoryId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "post/:id"} render={(props) => {
						analytics_push('article', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<PostViewer
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									postId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'partenaires'} render={(props) => {
						analytics_push('partenaires', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<PartenairesScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'partenaire/:id'} render={(props) => {
						analytics_push('partenaire', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<PartenaireViewer
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									partenaireId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'escales'} render={(props) => {
						analytics_push('escales', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<EscalesScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'escale/:id'} render={(props) => {
						analytics_push('escale', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<EscaleViewer
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									escaleId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "visites_duree"} render={(props) => {
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<VisitsDuree
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "visites"} render={(props) => {
						analytics_push('visites', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<VisitsScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "visite_preview/:id"} render={(props) => {
						analytics_push('visite_preview', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<VisitPreview
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									visitId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "visite/:id"} render={(props) => {
						analytics_push('visite_view', props.match.params.id);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<VisitViewer
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
									visitId={props.match.params.id}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'evenements'} render={(props) => {
						analytics_push('evenements', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<EventsScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'infos'} render={(props) => {
						analytics_push('infos', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<InfosScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route exact path={this.appOptions.basePath + 'signalement'} render={(props) => {
						analytics_push('signalement', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<SignalementsScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route path={this.appOptions.basePath + "help"} render={(props) => {
						analytics_push('aide', 0);
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return(
								<HelpScreen
									historyProps={props}
									historyHandler={this.historyHandler}
									handleClickEvent={this.handleClickEvent}
									appOptions={this.appOptions}
									appData={this.appData}
									splashData={this.splashData[this.selectedLanguageIndex]}
								/>
							);
						}
					}} />

					<Route render={(props) => {
						if (this.localStorageDataInit === false) { this.checkLocalStorageData(props); }
						if (this.redirectToString.length > 0) { return this.redirectTo(); }
						else {
							return (
								<div className="page-pageUnknown">
									<UnknownScreen
										appOptions={this.appOptions}
										appData={this.appData}
									/>
								</div>
							);
						}
					}} />

				</Switch>
			</Router>
		);
	}


	///////////////////////////////////////
	// Handle Click Event
	handleClickEvent(context, value, historyProps){

		let body = document.querySelector('body');

		switch (context) {

			case "dispatch_box":
			case "category_box":
			case "related_post":
			case "partenaires_box":
			case "visites_box":
			case "visite_view":
			case "escales_box":
				historyProps.history.push(this.appOptions.basePath + value);
				break;

			case "partenaires_list":
				historyProps.history.push(this.appOptions.basePath + 'partenaires');
				break;

			case "escales_list":
				historyProps.history.push(this.appOptions.basePath + 'escales');
				break;

			case "infos":
				historyProps.history.push(this.appOptions.basePath + 'infos');
				break;

			case "agenda":
				historyProps.history.push(this.appOptions.basePath + 'evenements');
				break;

			case "signalement":
				historyProps.history.push(this.appOptions.basePath + 'signalement');
				break;

			case "visits_duree":
				if (typeof(Storage) !== "undefined") { localStorage.setItem('visits_selectedDuree', value); }
				historyProps.history.push(this.appOptions.basePath + 'visites');
				break;

			case "visites_list":
				if (this.appData.visites_clock === true) { historyProps.history.push(this.appOptions.basePath + 'visites_duree'); }
				else { historyProps.history.push(this.appOptions.basePath + 'visites'); }
				break;

			case 'navbar':
				if (body) { body.classList.remove('ww-navbar--visible'); }
				historyProps.history.push(this.appOptions.basePath + value);
				break;

			case 'dispatch_back':
				if (body) { body.classList.remove('ww-navbar--visible'); }
				historyProps.history.push(this.appOptions.basePath);
				break;

			case 'generic_back':
				if (body) { body.classList.remove('ww-navbar--visible'); }
				historyProps.history.goBack();
				break;

			default:
				break;

		}

	}


	///////////////////////////////////////
	// Set Splash Data
	setSplashData(historyProps, splashData, splashDataMD5, splashSelectedLanguageIndex, dataUpdated){

		if (dataUpdated) {
			this.splashData = splashData;
			this.splashDataMD5 = splashDataMD5;

			// Save Splash Data to Local Storage
			if (typeof(Storage) !== 'undefined') {
				localStorage.setItem('splashData', JSON.stringify(this.splashData));
				localStorage.setItem('splashDataMD5', splashDataMD5);
				localStorage.setItem('splashDataTs', Date.now());
			}
		}

		// Store Selected Language
		this.selectedLanguageIndex = splashSelectedLanguageIndex;

		// Check if selected language has changed and if we need to reload app data
		if (typeof(Storage) !== 'undefined') {
			const lsSelectedLanguageIndex = localStorage.getItem('selectedLanguageIndex');
			if (typeof(lsSelectedLanguageIndex) !== 'undefined'
				&& lsSelectedLanguageIndex !== null
				&& parseInt(lsSelectedLanguageIndex, 10) !== this.selectedLanguageIndex) {
				localStorage.removeItem('appDataTs');
				this.appDataMD5 = '';
				this.localStorageDataInit = false;
			}
			localStorage.setItem('selectedLanguageIndex', this.selectedLanguageIndex);
		}

		// Go to Dispatch
		historyProps.history.push(this.appOptions.basePath + 'dispatch');
	}


	///////////////////////////////////////
	// Set App Data
	setAppData(appData, appDataMD5){

		this.appData = appData;
		this.appDataMD5 = appDataMD5;

		// Save App Data to Local Storage
		if (typeof(Storage) !== 'undefined') {
			localStorage.setItem('appData', JSON.stringify(this.appData));
			localStorage.setItem('appDataMD5', this.appDataMD5);
			localStorage.setItem('appDataTs', Date.now());
		}

	}


	///////////////////////////////////////
	// Check Saved Data from Local Storage
	checkLocalStorageData(routeProps){
		this.localStorageDataInit = true;

		// Check if Local Storage Available
		if (typeof(Storage) !== 'undefined') {
			this.localStorageDataAvailable = true;

			// Try to get Splash Data from Local Storage
			const lsSplashData = localStorage.getItem('splashData');
			const lsSplashDataMD5 = localStorage.getItem('splashDataMD5');
			const lsSplashDataTs = localStorage.getItem('splashDataTs');
			const lsSelectedLanguageIndex = localStorage.getItem('selectedLanguageIndex');
			if (typeof(lsSplashDataTs) !== 'undefined'
				&& lsSplashDataTs !== null
				&& typeof(lsSplashData) !== 'undefined'
				&& lsSplashData !== null
				&& typeof(lsSplashDataMD5) !== 'undefined'
				&& lsSplashDataMD5 !== null
				&& typeof(lsSelectedLanguageIndex) !== 'undefined'
				&& lsSelectedLanguageIndex !== null
				// Splash data have an expiration time of 30 days
				&& (Date.now() - lsSplashDataTs < 30 * 24 * 60 * 60 * 1000)
				) {

				// Here we know Splash data are valid
				this.splashData = JSON.parse(lsSplashData);
				this.splashDataMD5 = lsSplashDataMD5;
				this.selectedLanguageIndex = parseInt(lsSelectedLanguageIndex, 10);


				// Try to get App Data from Local Storage
				const lsAppData = localStorage.getItem('appData');
				const lsAppDataMD5 = localStorage.getItem('appDataMD5');
				if (typeof(lsAppData) !== 'undefined'
					&& lsAppData !== null
					&& typeof(lsAppDataMD5) !== 'undefined'
					&& lsAppDataMD5 !== null
					) {
					this.appData = JSON.parse(lsAppData);
					this.appDataMD5 = lsAppDataMD5;

					// Check is AppData are out of date
					const lsAppDataTs = localStorage.getItem('appDataTs');
					if (typeof(lsAppDataTs) === 'undefined'
						|| lsAppDataTs === null
						// App data have an expiration time of 2 hours
						|| (Date.now() - lsAppDataTs > 2 * 60 * 60 * 1000)
						){
						this.appDataMD5 = '';
					}
				}
				else {
					// Splash Data are valid but App Data absent => force redirect to "Dispatch"
					if (routeProps.location.pathname !== this.appOptions.basePath + 'dispatch') { this.redirectToString = 'dispatch'; }
				}
			}
			else {
				// Splash Data Invalid
				this.redirectToString = 'home';
			}
		}
		else {
			// Local Storage does not work
			this.redirectToString = 'home';
		}
	}


	///////////////////////////////////////
	// Redirect
	redirectTo(){
		let redirect = (this.redirectToString === 'home') ? '' : this.redirectToString;
		this.redirectToString = '';
		return(<Redirect to={this.appOptions.basePath + redirect} />);
	}


	///////////////////////////////////////
	// History handler
	historyHandler(historyProps){
		let body = document.querySelector('body');
		if (body) { body.classList.remove('ww-navbar--visible'); }
	}


}

export default App;
