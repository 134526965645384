// React
import React from 'react';

// Tools / Library
import {escStr, processGeolocationDistance} from '../Utils'
import {isEmpty, sortBy} from 'lodash'
import {IoLocationSharp} from 'react-icons/io5';
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {AiFillClockCircle} from 'react-icons/ai';
import {BiWalk} from 'react-icons/bi';

// Components
import Navbar from './Navbar';
import Box from './Box';

const nl2br = require('react-nl2br');


class VisitsScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	visitsGeolocation = null;
	selectedDuree = -1;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
		};

		// Init Filters
		if (typeof(Storage) !== "undefined") {
			localStorage.removeItem('partenaire_selectedCategorieId');
			let lsSelectedDuree = localStorage.getItem('visits_selectedDuree');
			if (typeof(lsSelectedDuree) !== "undefined" && lsSelectedDuree !== null) {
				this.selectedDuree = parseInt(lsSelectedDuree, 10);
			}
		}

		// Bind this to functions
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Filters
		if (typeof(Storage) !== "undefined") {
			localStorage.removeItem('partenaire_selectedCategorieId');
			let lsSelectedDuree = localStorage.getItem('visits_selectedDuree');
			if (typeof(lsSelectedDuree) !== "undefined" && lsSelectedDuree !== null) {
				this.selectedDuree = parseInt(lsSelectedDuree, 10);
			}
		}

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.visitsGeolocation = location;
							// Save Geolocation to Local Storage
							if (typeof(Storage) !== 'undefined') {
								localStorage.setItem('lastGeolocationLatitude', location.coords.latitude);
								localStorage.setItem('lastGeolocationLongitude', location.coords.longitude);
								localStorage.setItem('lastGeolocationAccuracy', location.coords.accuracy);
								localStorage.setItem('lastGeolocationTs', Date.now());
							}
							this.setState({ geolocation : true });
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({ geolocation : true });
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({ geolocation : true });
			}
		}

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		///////////////////////////////////////
		// Geolocation Warning
		let geolocation_warning = "";
		if (this.state.geolocation === true && this.visitsGeolocation === null) {
			geolocation_warning =
				<div className="ww-banner">
					{nl2br(escStr(this.props.appData.tStrings.geolocalisation_desactivee))}
				</div>;
		}


		///////////////////////////////////////
		// List Content
		let listContent = "";
		if (!isEmpty(this.props.appData.visites)) {

			// If Geolocation activated, sort by distance
			let sortedVisits = [];
			for (let i=0; i<this.props.appData.visites.length; i++) {
				let newVisit = this.props.appData.visites[i];
				if (this.selectedDuree !== -1 && parseInt(newVisit.duree, 10) !== this.selectedDuree) { continue; }
				newVisit.distance_to = 0;
				if (this.state.geolocation === true && this.visitsGeolocation !== null) {
					newVisit.distance_to = processGeolocationDistance(this.visitsGeolocation.coords.latitude, this.visitsGeolocation.coords.longitude, newVisit.points[0].latitude, newVisit.points[0].longitude, false);
				}
				sortedVisits.push(newVisit);
			}
			sortedVisits = sortBy(sortedVisits, [function(o) { return o.distance_to; }]);

			if (!isEmpty(sortedVisits)) {
				listContent =
					<ul className="ww-boxList">
						{sortedVisits.map( visit => {

							// Distance to start
							let boxPictosDistanceToStart = "";
							if (this.state.geolocation === true && this.visitsGeolocation !== null) {
								let userDistance = processGeolocationDistance(this.visitsGeolocation.coords.latitude, this.visitsGeolocation.coords.longitude, visit.points[0].latitude, visit.points[0].longitude, true);
								boxPictosDistanceToStart =
										<li><IoLocationSharp size="26px" /><span>{userDistance}</span></li>;
							}

							// Durée
							let boxPictosDuree =
								<li><AiFillClockCircle size="26px" /><span>{visit.duree}H</span></li>;

							// Distance
							let boxPictosDistance =
								<li><BiWalk size="26px" /><span>{visit.distance}Km</span></li>;

							// Pictos list
							let boxPictos =
								<ul className="ww-box__overlayPictos">
									{boxPictosDistanceToStart}
									{boxPictosDuree}
									{boxPictosDistance}
								</ul>;

							// Link
							let boxLink = "visite_preview/" + visit.id;

							let boxContent =
								<div className="ww-box__overlay">
									<div className="ww-box__overlayContent">
										<p className="ww-box__title">{escStr(visit.title)}</p>
									</div>
									{boxPictos}
								</div>;

							return (
								<li key={visit.id}>
									<Box
										boxClass="ww-box__visite"
										boxImage={visit.image}
										boxImageWidth={visit.image_width}
										boxImageHeight={visit.image_height}
										boxContent={boxContent}
										handleClickEvent={this.props.handleClickEvent}
										handleClickContext='visites_box'
										handleClickValue={boxLink}
										handleClickHistoryProps={this.props.historyProps}
										/>
								</li>);
						})}
					</ul>;
			}
			else {
				listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
			}
		}
		else {
			listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
		}


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'visites') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}


		return(
			<div className="page-dispatch page-visites-list">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(title)}</h2>
					{geolocation_warning}
					{listContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>
			</div>
		);
	}

}

export default VisitsScreen;
