// React
import React from 'react';

// Tools / Library
import {escStr} from '../Utils'
//import {IoLocationSharp} from 'react-icons/io5';
//import {IoMdHelpCircle} from 'react-icons/io';
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';
import Box from './Box';

const nl2br = require('react-nl2br');


class HelpScreen extends React.Component {

	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		if (typeof(this.props.historyHandler) !== 'undefined') { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {
		// Scroll to top
		window.scrollTo(0, 0);

		let helpContent =
			<div className="help--help">
				{ this.props.appData.help.map( (item, count) => {
					let button = "";
					if (item.button_link.length > 0 &  item.button_text.length > 0) {
						button = <p style={{ marginTop : "24px" }}><a className="ww-button--small" target="_blank" href={item.button_link} rel="noopener noreferrer">{item.button_text}</a></p>;
					}
					let image = "";
					if (item.image !== ""
						&& item.image_width > 0
						&& item.image_height >0) {
						image =
							<Box
								boxClass="ww-box--help"
								boxImage={item.image}
								boxImageWidth={item.image_width}
								boxImageHeight={item.image_height}
								/>;
					}

					let help_title = "";
					if (item.title.length > 0) { help_title = <h2 style={{color:this.props.appOptions.primaryColor}}>{escStr(item.title)}</h2>; }

					let help_section_class = (item.separator) ? 'ww-dialog__section ww-dialog__section--separator' : 'ww-dialog__section';

					if (item.image_position === "above") {
						return (
							<div className={help_section_class} key={count}>
								{image}
								{help_title}
								<p>{nl2br(escStr(item.text))}</p>
								{button}
							</div>
						);
					}
					else if (item.image_position === "middle") {
						return (
							<div className={help_section_class} key={count}>
								<h2>{escStr(item.title)}</h2>
								{image}
								<p>{nl2br(escStr(item.text))}</p>
								{button}
							</div>
						);
					}
					else {
						return (
							<div className={help_section_class} key={count}>
								<h2>{escStr(item.title)}</h2>
								<p>{nl2br(escStr(item.text))}</p>
								{image}
								{button}
							</div>
						);
					}
				})}
			</div>;

		// Help Vide
		// => Remettre aussi style CSS de help--geolocation
		// <h2 className="ww-boxList__title">{escStr(this.props.splashData.geolocation)}</h2>

		let geolocationContent =
			<div  className="help--geolocation">
				{ this.props.splashData.geolocation_help.map( (item, count) => {
					let button = "";
					if (item.button_link.length > 0 &  item.button_text.length > 0) {
						button = <p style={{ marginTop : "24px" }}><a className="ww-button--small" target="_blank" href={item.button_link} rel="noopener noreferrer">{item.button_text}</a></p>;
					}
					let image = "";
					if (item.image !== ""
						&& item.image_width > 0
						&& item.image_height >0) {
						image =
							<Box
								boxClass="ww-box--help"
								boxImage={item.image}
								boxImageWidth={item.image_width}
								boxImageHeight={item.image_height}
								/>;
					}

					let help_title = "";
					if (item.title.length > 0) { help_title = <h2 style={{color:this.props.appOptions.primaryColor}}>{escStr(item.title)}</h2>; }

					let help_section_class = (item.separator) ? 'ww-dialog__section ww-dialog__section--separator' : 'ww-dialog__section';

					if (item.image_position === "above") {
						return (
							<div className={help_section_class} key={count}>
								{image}
								{help_title}
								<p>{nl2br(escStr(item.text))}</p>
								{button}
							</div>
						);
					}
					else if (item.image_position === "middle") {
						return (
							<div className={help_section_class} key={count}>
								<h2>{escStr(item.title)}</h2>
								{image}
								<p>{nl2br(escStr(item.text))}</p>
								{button}
							</div>
						);
					}
					else {
						return (
							<div className={help_section_class} key={count}>
								<h2>{escStr(item.title)}</h2>
								<p>{nl2br(escStr(item.text))}</p>
								{image}
								{button}
							</div>
						);
					}
				})}
			</div>;


		return(
			<div className="page-dispatch page-pageHelp">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(this.props.appData.tStrings.aide)}</h2>
					{helpContent}
					{geolocationContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>
			</div>
		);
	}

}

export default HelpScreen;
