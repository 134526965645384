// React
import React from 'react';

// Tools / Library
import {escStr} from '../Utils'
import {IoIosArrowDown} from 'react-icons/io';

class ListSelect extends React.Component {

	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = { open: false };
	}


	///////////////////////////////////////
	// Render
	render() {

		let listClass = 'ww-listSelect';
		if (this.props.listClass) { listClass += ' ' + this.props.listClass; }
		if (this.state.open === true) { listClass += ' ww-listSelect--opened'; }
		let selectedTitle = '';
		let resetOption = '';
		if (this.props.listEmpty) {
			selectedTitle = <span>&nbsp;:&nbsp;{this.props.listEmpty}</span>;
			resetOption =
				<li key="-1" onClick={ () => {
					this.setState( { open : false } );
					setTimeout( () => this.props.handleSelect(this.props.listId, -1), 200 );
					} }>{escStr(this.props.listEmpty)}</li>
		}
		let selectedIndex = -1;
		if (typeof(this.props.listSelectedId) !== 'undefined' && this.props.listSelectedId !== -1) {
			for (let i=0; i<this.props.listItems.length; i++) {
				if (this.props.listItems[i].id === this.props.listSelectedId) {
					selectedIndex = i;
					break;
				}
			}
		}
		if (selectedIndex !== -1) {
			selectedTitle = <span>&nbsp;:&nbsp;{escStr(this.props.listItems[selectedIndex].name)}</span>;
		}

		return(
			<div className={listClass}>
				<div className="ww-listSelect__title" onClick={ () => this.setState( { open : !this.state.open } ) }>
					{this.props.listTitle}{selectedTitle}
					<IoIosArrowDown />
				</div>
				<div className="ww-listSelect__list">
					<ul>
						{resetOption}
						{this.props.listItems.map( item =>
							<li key={item.id} onClick={ () => {
								this.setState( { open : false } );
								setTimeout( () => this.props.handleSelect(this.props.listId, item.id), 200 );
							}}>{escStr(item.name)}</li>
						)}
					</ul>
				</div>
			</div>
		);

	}

}

export default ListSelect;
