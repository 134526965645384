// React
import React from 'react';

// Tools / Library
import {escStr, processGeolocationDistance} from '../Utils'
import {isEmpty, sortBy} from 'lodash'
import {IoLocationSharp} from 'react-icons/io5';
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';
import Box from './Box';
import ListSelect from './ListSelect';

const nl2br = require('react-nl2br');


class PartenairesScreen extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	partenairesGeolocation = null;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			geolocation: false,
			selectedCategorieId: -1,
		};

		// Bind this to functions
		this.handleSelect = this.handleSelect.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Filters
		let initSelectedCategorieId = -1;
		if (typeof(Storage) !== "undefined") {
			localStorage.removeItem('visits_selectedDuree');
			let lsSelectedCategorieId = localStorage.getItem('partenaire_selectedCategorieId');
			if (typeof(lsSelectedCategorieId) !== "undefined"
				&& lsSelectedCategorieId !== null) {
				initSelectedCategorieId = parseInt(lsSelectedCategorieId, 10);
			}
		}

		// Init Geolocation
		if (this.state.geolocation === false) {
			if ('geolocation' in navigator) {
				navigator.geolocation.getCurrentPosition(
					location => {
						if (this.mounted === true) {
							//console.log('Geolocation', location);
							this.partenairesGeolocation = location;
							// Save Geolocation to Local Storage
							if (typeof(Storage) !== 'undefined') {
								localStorage.setItem('lastGeolocationLatitude', location.coords.latitude);
								localStorage.setItem('lastGeolocationLongitude', location.coords.longitude);
								localStorage.setItem('lastGeolocationAccuracy', location.coords.accuracy);
								localStorage.setItem('lastGeolocationTs', Date.now());
							}
							this.setState({
								selectedCategorieId: initSelectedCategorieId,
								geolocation : true
							});
						}
					},
					positionError => {
						if (this.mounted === true) {
							this.setState({
								selectedCategorieId: initSelectedCategorieId,
								geolocation : true
							});
						}
					},
					{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
				);
			}
			else {
				this.setState({
					selectedCategorieId: initSelectedCategorieId,
					geolocation : true
				});
			}
		}
		else { this.setState({ selectedCategorieId: initSelectedCategorieId, }); }

	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		///////////////////////////////////////
		// Geolocation Warning
		let geolocation_warning = "";
		if (this.state.geolocation === true && this.partenairesGeolocation === null) {
			geolocation_warning =
				<div className="ww-banner">
					{nl2br(escStr(this.props.appData.tStrings.geolocalisation_desactivee))}
				</div>;
		}


		///////////////////////////////////////
		// List Content
		let listContent = "";
		if (!isEmpty(this.props.appData.partenaires)) {

			// If Geolocation activated, sort by distance
			let sortedPartenaires = [];
			for (let i=0; i<this.props.appData.partenaires.length; i++) {

				// Filter Categorie
				let filterCategorie = false;
				if (this.state.selectedCategorieId === -1) { filterCategorie = true; }
				else {
					for (let j=0; j<this.props.appData.partenaires[i].categories.length; j++) {
						if (this.props.appData.partenaires[i].categories[j] === this.state.selectedCategorieId) { filterCategorie = true; }
					}
				}
				if (!filterCategorie) { continue; }

				let newPartenaire = this.props.appData.partenaires[i];
				newPartenaire.distance_to = 0;
				if (this.state.geolocation === true && this.partenairesGeolocation !== null) {
					newPartenaire.distance_to = processGeolocationDistance(this.partenairesGeolocation.coords.latitude, this.partenairesGeolocation.coords.longitude, this.props.appData.partenaires[i].latitude, this.props.appData.partenaires[i].longitude, false);
				}
				// If no geolocation => Random order
				else { newPartenaire.distance_to = Math.floor(Math.random() * 100); }
				sortedPartenaires.push(newPartenaire);
			}
			sortedPartenaires = sortBy(sortedPartenaires, [function(o) { return o.distance_to; }]);

			if (!isEmpty(sortedPartenaires)) {
				listContent =
				<ul className="ww-boxList">
					{sortedPartenaires.map( partenaire => {

						// Distance
						let boxPictosDistance = "";
						if (this.state.geolocation === true && this.partenairesGeolocation !== null) {
							let userDistance = processGeolocationDistance(this.partenairesGeolocation.coords.latitude, this.partenairesGeolocation.coords.longitude, partenaire.latitude, partenaire.longitude, true);
							boxPictosDistance =
									<li><IoLocationSharp size="26px" /><span>{userDistance}</span></li>;
						}

						// Pictos list
						let boxPictos =
							<ul className="ww-box__overlayPictos">
								{boxPictosDistance}
							</ul>;

						// Link
						let boxLink = "partenaire/" + partenaire.id;

						// Categories
						let pCategories = '';
						for (let i=0; i<partenaire.categories.length; i++) {
							for (let j=0; j<this.props.appData.partenaires_categories.length; j++) {
								if (partenaire.categories[i] === this.props.appData.partenaires_categories[j].id) {
									if (pCategories.length === 0) { pCategories += this.props.appData.partenaires_categories[j].name; }
									else { pCategories += ', ' + this.props.appData.partenaires_categories[j].name; }
								}
							}
						}

						// Image
						let imageClass = '';
						let imageUrl = partenaire.image;
						let imageWidth = partenaire.image_width;
						let imageHeight = partenaire.image_height;
						if (imageUrl.length === 0) {
							imageClass = 'ww-box__nooverlay';
							imageUrl = this.props.appData.default_image.url;
							imageWidth = this.props.appData.default_image.width;
							imageHeight = this.props.appData.default_image.height;
						}

						let boxContent =
							<div className="ww-box__overlay">
								<div className="ww-box__overlayContent">
									<p className="ww-box__taxonomy">{escStr(pCategories)}</p>
									<p className="ww-box__title">{escStr(partenaire.title)}</p>
								</div>
								{boxPictos}
							</div>;

						return (
							<li key={partenaire.id}>
								<Box
									boxClass={imageClass}
									boxImage={imageUrl}
									boxImageWidth={imageWidth}
									boxImageHeight={imageHeight}
									boxContent={boxContent}
									handleClickEvent={this.props.handleClickEvent}
									handleClickContext='partenaires_box'
									handleClickValue={boxLink}
									handleClickHistoryProps={this.props.historyProps}
									/>
							</li>);
					}
					)}
				</ul>;
			}
			else {
				listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
			}
		}
		else {
			listContent = <h3 className="ww-boxList--empty">{escStr(this.props.appData.tStrings.aucun_resultat)}</h3>;
		}


		///////////////////////////////////////
		// Filter Categorie
		let filterCategorie = '';
		if (!isEmpty(this.props.appData.partenaires_categories)) {
			filterCategorie =
				<ListSelect
					handleSelect={this.handleSelect}
					listId="partenaires-categorie"
					listClass="ww-listCategorie--partenairesCategorie"
					listTitle={this.props.appData.tStrings.filtrer_categorie}
					listEmpty={this.props.appData.tStrings.toutes}
					listItems={this.props.appData.partenaires_categories}
					listSelectedId={this.state.selectedCategorieId}
				/>;
		}


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'partenaires') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}


		return(
			<div className="page-dispatch">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title ww-marginBottom--none">{escStr(title)}</h2>
					{geolocation_warning}
					{filterCategorie}
					{listContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>
			</div>
		);
	}


	///////////////////////////////////////
	// Handle Select Categorie
	handleSelect(listId, value) {
		if (listId === "partenaires-categorie") {
			this.setState({ selectedCategorieId : value });
			if (typeof(Storage) !== "undefined") { localStorage.setItem('partenaire_selectedCategorieId', value); }
		}
	}

}

export default PartenairesScreen;
