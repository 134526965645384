
///////////////////////////////////////
// Fetch with Timeout function
export function fetchWithTimeout(uri, options, time) {

	const controller = new AbortController();
	const config = { ...options, signal: controller.signal };
	var timeout = setTimeout(() => { controller.abort() }, time);

	return fetch(uri, config)
		.then(response => {
			clearTimeout(timeout);
			if (!response.ok) {
				throw new Error(`${response.status}: ${response.statusText}`);
			}
			return response;
		})
		.catch(error => {
			if (error.name === 'AbortError') {
				throw new Error('Response timed out');
			}
			throw new Error(error.message);
		});

}


///////////////////////////////////////
// Escape strings from JSON
export function escStr(str) {

	let escStr = str.replace(/&rsquo;/g, "'");
	escStr = escStr.replace(/&amp;/g, "&");

	return escStr;

}


///////////////////////////////////////
// Get file blob
export function getFileBlob(url, callback) {

	let xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.responseType = "blob";
	xhr.addEventListener("load", function () { callback(xhr); }, false);
	xhr.addEventListener("error", function () { callback(xhr); }, false);
	xhr.send();

}


///////////////////////////////////////
// Convert blob to ArrayBuffer
export function blobToArrayBuffer(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.addEventListener('loadend', (e) => { resolve(reader.result); });
		reader.addEventListener('error', reject);
		reader.readAsArrayBuffer(blob);
	});
}


///////////////////////////////////////
// Process Geolocation distance
export function processGeolocationDistance(lat1,lon1,lat2,lon2,textFormat) {
	var R = 6371;
	var dLat = (lat2-lat1) * Math.PI / 180;
	var dLon = (lon2-lon1) * Math.PI / 180;
	var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
		Math.cos(lat1 * Math.PI / 180 ) * Math.cos(lat2 * Math.PI / 180 ) *
		Math.sin(dLon/2) * Math.sin(dLon/2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d = R * c;
	if (textFormat === true) {
		if (d > 1) { return Math.round(d) + "Km"; }
		else if (d<=1) { return Math.round(d*1000) + "m"; }
		return d;
	}
	return Math.round(d*1000);
}


///////////////////////////////////////
// Leaflet Tile Names
export function xyz(bounds, zoom) {
	var min = project(bounds[1][1],bounds[0][0], zoom);
	var max = project(bounds[0][1],bounds[1][0], zoom);
	var tiles = [];

	for (var x = min.x; x <= max.x; x++) {
		for (var y = min.y; y <= max.y; y++) {
			tiles.push({ x: x, y: y, z: zoom });
		}
	}
	return tiles;
}

function project(lat,lng,zoom) {
	var R = 6378137,
		sphericalScale = 0.5 / (Math.PI * R);

	var d = Math.PI / 180,
		max = 1 - 1E-15,
		sin = Math.max(Math.min(Math.sin(lat * d), max), -max),
		scale = 256 * Math.pow(2, zoom);

	var point = {
		x: R * lng * d,
		y: R * Math.log((1 + sin) / (1 - sin)) / 2
	};

	point.x = Math.floor((scale * (sphericalScale * point.x + 0.5)) / 256);
	point.y = Math.floor((scale * (-sphericalScale * point.y + 0.5)) / 256);

	return point;
}


///////////////////////////////////////
// Map URL
export function mapsURL(latitude, longitude) {
	if ((navigator.platform.indexOf("iPhone") !== -1) ||
		(navigator.platform.indexOf("iPad") !== -1) ||
		(navigator.platform.indexOf("iPod") !== -1)) {
		return `maps://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=`;
	}
	else {
		return `https://maps.google.com/maps?daddr=${latitude},${longitude}&amp;ll=`;
	}
}


///////////////////////////////////////
// Analytics
export function analytics_get() {

	if (typeof(Storage) !== 'undefined') {
		const lsAnalytics = localStorage.getItem('analytics');
		if (typeof(lsAnalytics) !== 'undefined' && lsAnalytics !== null) {
			const analytics = JSON.parse(lsAnalytics);
			if (!Array.isArray(analytics)) { return []; }
			else { return analytics; }
		}
	}

	return [];
}

export function analytics_push(context, id) {

	if (typeof(Storage) !== 'undefined') {
		let analytics = [];

		const lsAnalytics = localStorage.getItem('analytics');
		if (typeof(lsAnalytics) !== 'undefined' && lsAnalytics !== null) {
			analytics = JSON.parse(lsAnalytics);
			if (!Array.isArray(analytics)) { analytics = []; }
		}

		// Date String
		const date = new Date();
		let dateString = date.getFullYear();
		dateString += '-';
		const month = date.getMonth() + 1;
		dateString += (month < 10) ? '0' + month : month;
		dateString += '-';
		const day = date.getDate();
		dateString += (day < 10) ? '0' + day : day;

		// Loop on analytics items and increment if found
		let itemFound = false;
		for (let i=0; i<analytics.length; i++) {
			if (analytics[i].date === dateString && analytics[i].context === context && analytics[i].id === id) {
				analytics[i].count++;
				itemFound = true;
				break;
			}
		}

		// Item not found => create
		if (!itemFound) { analytics.push({ date: dateString, context: context, id: id, count: 1}); }

		// Save
		localStorage.setItem('analytics', JSON.stringify(analytics));
	}

}
