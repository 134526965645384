// React
import React from 'react';

// Tools / Library
import Loader from 'react-loader-spinner';
import {escStr, mapsURL, xyz, getFileBlob, blobToArrayBuffer} from '../Utils';
import {MapContainer, MapConsumer, TileLayer, Marker, Polyline} from 'react-leaflet';
import L from 'leaflet';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {BiTargetLock, BiWalk} from 'react-icons/bi';
import {FaMapMarkedAlt} from 'react-icons/fa';
import {IoLocationSharp} from 'react-icons/io5';
import {IoIosArrowDropright, IoMdDownload} from 'react-icons/io';
import {AiFillClockCircle} from 'react-icons/ai';
import {indexOf} from 'lodash';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';


// Components
import Navbar from './Navbar';
import UnknownScreen from './UnknownScreen';

// Ressources
import mapImgStart from '../Images/map-start.svg';
import mapImgEnd from '../Images/map-end.svg';
import mapImgUser from '../Images/map-user.svg';
import '../Css/leaflet.css';

const nl2br = require('react-nl2br');


class VisitPreview extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	userLocation = null;
	visit = null;
	markers = [];
	etapes = [];
	etapesMarkerIndex = [];
	points = [];
	map = null;
	mapOptions = {
		mapOpacity: 0.6,
		mapMarkerSize: [28, 40],
		mapMarkerAnchor: [14, 40],
		mapLineColor: "#0037ff",
		mapLineOpacity: 0.6,
		mapLineWidth: 5,
		mapLineDash: [],
		loaderColor: "#0037ff",
		loaderSize: 80,
	};


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init Visit
		for (let i=0; i<props.appData.visites.length; i++) {
			if (props.appData.visites[i].id === parseInt(props.visitId, 10)) {
				this.visit = props.appData.visites[i];
				break;
			}
		}

		// Init Markers
		if (this.visit !== null) {
			let lastLat = 0
			let lastLng = 0
			for (let i=0; i<this.visit.points.length; i++) {
				let tPoint = this.visit.points[i];
				if (tPoint.type !== "point") {
					if (lastLat !== tPoint.latitude || lastLng !== tPoint.longituge) {
						this.markers.push([tPoint.latitude, tPoint.longitude]);
						lastLat = tPoint.latitude;
						lastLng = tPoint.longitude;
					}
					this.etapes.push(tPoint);
					if (this.markers.length > 0) { this.etapesMarkerIndex.push(this.markers.length - 1); }
					else { this.etapesMarkerIndex.push(0); }
				}
				this.points.push([tPoint.latitude, tPoint.longitude]);
			}
		}

		// Init State
		this.state = {
			loading: false,
			geolocation: false,
			centerMode: 0
		}

		// Bind this to functions
		this.handleDownload = this.handleDownload.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);

		// Init Geolocation
		this.geoLocationAvailable = false;
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				location => {
					if (this.mounted === true) {
						this.userLocation = location;
						this.setState({ geolocation : true });
					}
				},
				positionError => {
					if (this.mounted === true) {
						this.setState({ geolocation : true });
					}
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
			);
		}
		else {
			this.setState({ geolocation : true });
		}
	}


	///////////////////////////////////////
	// Component Did Update
	componentDidUpdate() {
		window.scrollTo(0, 0);

		// Update Map Position
		if (this.visit !== null && this.map !== null) {
			if (this.state.centerMode === 0 || this.userLocation === null) {
				this.map.setView([this.visit.center_latitude, this.visit.center_longitude], this.visit.center_zoom);
			}
			else if (this.userLocation !== null && this.state.centerMode === 1) {
				let markersArray = [];
				markersArray.push(L.marker([this.visit.points[0].latitude, this.visit.points[0].longitude]));
				markersArray.push(L.marker([this.visit.points[this.visit.points.length-1].latitude, this.visit.points[this.visit.points.length-1].longitude]));
				markersArray.push(L.marker([this.userLocation.coords.latitude, this.userLocation.coords.longitude]));
				let group = L.featureGroup(markersArray);
				this.map.fitBounds(group.getBounds(), {padding: [50, 50]});
			}
		}
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		///////////////////////////////////////
		// Geolocation Warning
		let geolocation_warning = "";
		if (this.state.geolocation === true && this.userLocation === null) {
			geolocation_warning =
				<div className="ww-banner">
					{nl2br(escStr(this.props.appData.tStrings.geolocalisation_desactivee))}
				</div>;
		}


		/* ////////////////////////////////////////////////////////////////////////////
		// Map
		//////////////////////////////////////////////////////////////////////////// */

		///////////////////////////////////////
		// User position
		const mapUser = new L.Icon({
			iconUrl: mapImgUser,
			iconSize: [20, 20],
			iconAnchor: [10, 10]
		});
		let userMarker = "";
		if (this.state.geolocation === true && this.userLocation !== null) {
			userMarker = <Marker position={[this.userLocation.coords.latitude, this.userLocation.coords.longitude]} icon={mapUser}></Marker>;
		}


		///////////////////////////////////////
		// Map Center button
		let centerButton = "";

		// Visite center
		if (this.state.centerMode === 0) {
			centerButton = <div className="ww-visit__mapCenterButton" onClick={ () => this.setState({ centerMode : 1 }) }><FaMapMarkedAlt size="18px" /></div>;
		}
		// User center
		if (this.state.centerMode === 1) {
			centerButton = <div className="ww-visit__mapCenterButton" onClick={ () => this.setState({ centerMode : 0 }) }><BiTargetLock size="26px" /></div>;
		}


		///////////////////////////////////////
		// Map
		const mapStart = new L.Icon({
			iconUrl: mapImgStart,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});
		const mapEnd = new L.Icon({
			iconUrl: mapImgEnd,
			iconSize: this.mapOptions.mapMarkerSize,
			iconAnchor: this.mapOptions.mapMarkerAnchor
		});

		// Map
		let visitMap = "";
		if (this.visit !== null) {
			visitMap =
				<div className="ww-visit__map">
					<MapContainer center={[this.visit.center_latitude, this.visit.center_longitude]} zoom={this.visit.center_zoom} zoomSnap={0.1}>
						<MapConsumer>{(map) => { this.map = map; return null; }}</MapConsumer>
						<TileLayer url={this.props.appData.mapProvider} opacity={this.mapOptions.mapOpacity} />
						{userMarker}
						{this.markers.map( (point, count) => {
							if (count === 0) {
								return(<Marker key={count} position={point} icon={mapStart}></Marker>);
							}
							else if (count === this.markers.length - 1) {
								return(<Marker key={count} position={point} icon={mapEnd}></Marker>);
							}
							else { return(''); }
						})}
						<Polyline color={this.mapOptions.mapLineColor} dashArray={this.mapOptions.mapLineDash} weight={this.mapOptions.mapLineWidth} positions={this.points} opacity={this.mapOptions.mapLineOpacity} />
					</MapContainer>
					{centerButton}
				</div>;
		}


		/* ////////////////////////////////////////////////////////////////////////////
		// Image / Text Content
		//////////////////////////////////////////////////////////////////////////// */

		// Start
		let visit_location = "";
		if (this.visit !== null
				&& typeof(this.visit.points[0].adresse) !== "undefined"
				&& this.visit.points[0].adresse !== null
				&& this.visit.points[0].adresse.length > 0) {
			visit_location =
				<li>
					<IoLocationSharp size="30px" />
					<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.visit.points[0].latitude, this.visit.points[0].longitude)}>
						<span className="ww-visitsInfos__title">{escStr(this.props.appData.tStrings.visite_depart)}</span>
						<span className="ww-visitsInfos__texte">{nl2br(escStr(this.visit.points[0].adresse))}</span>
						<span className="ww-visitsInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
					</a>
					<IoIosArrowDropright size="32px" className="ww-arrow" />
				</li>;
		}
		else if (this.visit !== null) {
			visit_location =
				<li>
					<IoLocationSharp size="30px" />
					<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.visit.points[0].latitude, this.visit.points[0].longitude)}>
						<span className="ww-visitsInfos__title">{escStr(this.props.appData.tStrings.visite_depart)}</span>
						<span className="ww-visitsInfos__texte">{this.visit.points[0].latitude} | {this.visit.points[0].longitude}</span>
						<span className="ww-visitsInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
					</a>
					<IoIosArrowDropright size="32px" className="ww-arrow" />
				</li>;
		}


		// Download
		let visit_download = "";
		if (this.visit !== null) {
			let visitDownloadedFlag = false;
			if (typeof(Storage) !== "undefined") {
				let downloadedVisitId = localStorage.getItem('downloaded_visit_id');
				if (typeof(downloadedVisitId) !== "undefined"
					&& downloadedVisitId !== null
					&& parseInt(downloadedVisitId, 10) === this.visit.id) {
					visitDownloadedFlag = true;
				}
			}
			if (visitDownloadedFlag === true) {
				visit_download =
					<li>
						<IoMdDownload size="30px" />
						<span className="ww-visitsInfos__title">{escStr(this.props.appData.tStrings.visite_telechargement)}</span>
						<span className="ww-visitsInfos__comment">{escStr(this.props.appData.tStrings.visite_deja_telechargee)}</span>
					</li>;

			}
			else {
				visit_download =
					<li onClick={ () => this.handleDownload() }>
						<IoMdDownload size="30px" />
						<span className="ww-visitsInfos__title">{escStr(this.props.appData.tStrings.visite_telechargement)}</span>
						<span className="ww-visitsInfos__comment">{escStr(this.props.appData.tStrings.visite_telechargement_detail)}</span>
						<IoIosArrowDropright size="32px" className="ww-arrow" />
					</li>;
			}
		}

		// Infos List
		let visitInfos = "";
		if (this.vist !== null) {
			visitInfos =
				<ul className="ww-visitsInfos">
					{visit_location}
					{visit_download}
				</ul>;
		}

		// Share
		let share = '';
		if (typeof(this.visit.link) !== 'undefined'
			&& this.visit.link.length > 0) {
			const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.visit.link;
			const twitterUrl = 'https://twitter.com/intent/tweet/?text=' + this.visit.title.replace(/ /g, '+') + '&url=' + this.visit.link;
				const linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&title=' + this.visit.title.replace(/ /g, '+') + '&url=' + this.visit.link;
			share =
				<div className="ww-share">
					<p className="ww-share__title">{escStr(this.props.appData.tStrings.partager)}</p>
					<ul>
						<li><a href={facebookUrl} target="_blank" rel="noreferrer noopener"><FaFacebookF size="24px" /></a></li>
						<li><a href={twitterUrl} target="_blank" rel="noreferrer noopener"><FaTwitter size="22px" /></a></li>
						<li><a href={linkedInUrl} target="_blank" rel="noreferrer noopener"><FaLinkedinIn size="22px" /></a></li>
					</ul>
				</div>;
		}

		let pageContent = "";
		let pageClass = "";
		// Loading Screen
		if (this.state.loading === true) {
			pageClass = "page-visitViewer";
			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{visitMap}
					<div className="ww-visit__content ww-visit__content--start">
						<ul className="ww-visit__pictos">
							<li className="ww-visit__pictos--duree"><AiFillClockCircle size="30px" />{this.visit.duree}H</li>
							<li className="ww-visit__pictos--distance"><BiWalk size="30px" />{this.visit.distance}Km</li>
							<li className="ww-visit__pictos--etapes"><IoLocationSharp size="30px" />{this.etapes.length}&nbsp;{this.props.appData.tStrings.visite_etapes}</li>
						</ul>
						<Loader
							className="ww-visit__loader"
							type="Oval"
							color={this.mapOptions.loaderColor}
							height={this.mapOptions.loaderSize}
							width={this.mapOptions.loaderSize}
						/>
					</div>
				</div>;
		}

		// Visit Preview
		else if (this.visit !== null) {
			pageClass = "page-visitViewer";
			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{visitMap}
					<div className="ww-visit__content ww-visit__content--start">
						<ul className="ww-visit__pictos">
							<li className="ww-visit__pictos--duree"><AiFillClockCircle size="30px" />{this.visit.duree}H</li>
							<li className="ww-visit__pictos--distance"><BiWalk size="30px" />{this.visit.distance}Km</li>
							<li className="ww-visit__pictos--etapes"><IoLocationSharp size="30px" />{this.etapes.length}&nbsp;{this.props.appData.tStrings.visite_etapes}</li>
						</ul>
						{geolocation_warning}
						<h1 className="ww-visit__title">{escStr(this.visit.title)}</h1>
						<p className="ww-visit__description">{nl2br(escStr(this.visit.description))}</p>
						{visitInfos}
						{share}
					</div>
					<div className="ww-visit__navButtons">
						<ul className="ww-visit__navButton__buttons">
							<li className="ww-visit__navButton__buttons--prev" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }><MdKeyboardArrowLeft size="26px" />{this.props.appData.tStrings.retour}</li>
							<li className="ww-visit__navButton__buttons--next" onClick={ () => this.props.handleClickEvent("visite_view", "visite/" + this.visit.id, this.props.historyProps) }><MdKeyboardArrowRight size="26px" />{escStr(this.props.appData.tStrings.visite_demarrer)}</li>
						</ul>
					</div>
				</div>;
		}

		// Unknown Screen
		else {
			pageClass = "page-pageViewer page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}


		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);

	}


	///////////////////////////////////////
	// Download Visit
	handleDownload() {

		// Try to save content in indexedDB
		var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
		if (indexedDB) {
			this.setState({ loading : true });

			// DB Open and Create
			//console.log("Create/Open new DB");
			var requestOpen = indexedDB.open("haropavisit", 1);
			requestOpen.onerror = function(event){
				//console.log("Create/Open Error");
				this.setState({ loading : false });
			};

			// DB Schema
			requestOpen.onupgradeneeded = function(event) {
				//console.log("IndexedDB OnUpgradeNeeded");
				let db = event.target.result;
				let objectStore = db.createObjectStore('haropavisit', { keyPath: 'id', autoIncrement: true });
				objectStore.createIndex('url', 'url', { unique: true });
				objectStore.createIndex('data', 'data', { unique: false });
				objectStore.createIndex('type', 'type', { unique: false });
			}

			// DB Open Success
			requestOpen.onsuccess = (function(event){
				//console.log("IndexedDB Create/Open Success");
				let openedDB = event.target.result;

				// First we clean the DB
				//console.log("First clean DB");
				let transaction = openedDB.transaction(['haropavisit'], 'readwrite');
				let requestClean = transaction.objectStore('haropavisit').clear();
				requestClean.onerror = function(event){
					//console.log("IndexedDB Clear Error");
					this.setState({ loading : false });
				};

				requestClean.onsuccess = (function(event){
					//console.log("IndexedDB Clean Success", event);

					// Build files array to store

					// Visite Images
					var filesArray = [];
					for (let i=0; i<this.visit.points.length; i++) {
						if (this.visit.points[i].type === "etape"
							&& typeof(this.visit.points[i].image) !== "undefined"
							&& this.visit.points[i].image.length > 0
							&& this.visit.points[i].image_width > 0
							&& this.visit.points[i].image_height > 0) {
							if (indexOf(filesArray, this.visit.points[i].image) === -1) {
								filesArray.push(this.visit.points[i].image);
							}
						}
					}

					// Visite Audio
					for (let i=0; i<this.visit.points.length; i++) {
						if (this.visit.points[i].type === "etape"
							&& typeof(this.visit.points[i].audio_file) !== "undefined"
							&& this.visit.points[i].audio_file.length > 0) {
							if (indexOf(filesArray, this.visit.points[i].audio_file) === -1) {
								filesArray.push(this.visit.points[i].audio_file);
							}
						}
					}

					// Map files
					// Create Bounds
					let minLat = 1000000000;
					let maxLat = -1000000000;
					let minLng = 1000000000;
					let maxLng = -1000000000;
					for (let i=0; i<this.visit.points.length; i++) {
						if (this.visit.points[i].latitude < minLat) { minLat = this.visit.points[i].latitude ;}
						if (this.visit.points[i].latitude > maxLat) { maxLat = this.visit.points[i].latitude ;}
						if (this.visit.points[i].longitude < minLng) { minLng = this.visit.points[i].longitude ;}
						if (this.visit.points[i].longitude > maxLng) { maxLng = this.visit.points[i].longitude ;}
					}
					let bounds = [[minLng, minLat],[maxLng, maxLat]];
					let tiles = xyz(bounds, this.visit.point_zoom);
					//console.log("tiles", tiles);
					for (let i=0; i<tiles.length; i++) {
						let server = 'a.';
						let tileUrl = this.props.appData.mapProvider.replace(/{s}\./g, server);
						tileUrl = tileUrl.replace(/{x}/g, tiles[i].x);
						tileUrl = tileUrl.replace(/{y}/g, tiles[i].y);
						tileUrl = tileUrl.replace(/{z}/g, tiles[i].z);
						tileUrl = tileUrl.replace(/{r}/g, '@2x');
						//console.log("tileUrl", tileUrl);
						if (indexOf(filesArray, tileUrl) === -1) {
							filesArray.push(tileUrl);
						}
					}

					//console.log("Files Array", filesArray);

					// Retrieve files from server
					this.dbFileLoaded = 0;
					for (let i=0; i<filesArray.length; i++) {
						getFileBlob(filesArray[i], (function(xhr){
							if (xhr.status === 200) {
								var blobType = xhr.response.type;
								// Convert file from blob to ArrayBuffer
								blobToArrayBuffer(xhr.response)
									.then( (result) => {
										// Store File in IndexedDB
										let newItem = { url: filesArray[i], data: result, type: blobType };
										let transaction = openedDB.transaction(['haropavisit'], 'readwrite');
										let addRequest = transaction.objectStore('haropavisit').add(newItem);
										addRequest.onsuccess = function() {
											//console.log("add request success");
										};
										transaction.oncomplete = function() {
											//console.log("add transaction complete");
										};
										transaction.onerror = function() {
											//console.log("add transaction error");
										};
									})
									.catch( error => {});
							}

							// Increment File Counter
							this.dbFileLoaded++;
							//console.log("Files Treated:", this.dbFileLoaded);
							if (this.dbFileLoaded === filesArray.length) {
								//console.log("All files treated !");
								if (typeof(Storage) !== "undefined") { localStorage.setItem('downloaded_visit_id', this.visit.id); }
								this.setState({ loading : false });
							}
						}).bind(this));
					}
				}).bind(this);
			}).bind(this);
		}
	}

}

export default VisitPreview;
