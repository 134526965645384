// React
import React from 'react';

// Tools / Library
import {escStr, mapsURL} from '../Utils'
import {IoLocationSharp} from 'react-icons/io5';
import {IoIosArrowDropright} from 'react-icons/io';
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';

// Components
import UnknownScreen from './UnknownScreen';
import Navbar from './Navbar';
import Box from './Box';

const nl2br = require('react-nl2br');


class EscaleViewer extends React.Component {

	///////////////////////////////////////
	// Variables
	selectedEscaleIndex = -1;


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		window.scrollTo(0, 0);
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		// Determine Index
		if (this.selectedEscaleIndex === -1) {
			for (let i=0; i<this.props.appData.escales.length; i++) {
				if (this.props.appData.escales[i].id === parseInt(this.props.escaleId, 10)) {
					this.selectedEscaleIndex = i;
					break;
				}
			}
		}

		let pageClass = '';
		let pageContent = '';

		if (this.selectedEscaleIndex !== -1) {

			// Feature Image
			let imageUrl = this.props.appData.escales[this.selectedEscaleIndex].image;
			let imageWidth = this.props.appData.escales[this.selectedEscaleIndex].image_width;
			let imageHeight = this.props.appData.escales[this.selectedEscaleIndex].image_height;
			if (imageUrl.length === 0) {
				imageUrl = this.props.appData.default_image.url;
				imageWidth = this.props.appData.default_image.width;
				imageHeight = this.props.appData.default_image.height;
			}
			const feature =
				<Box
					boxClass='ww-box__nooverlay'
					boxImage={imageUrl}
					boxImageWidth={imageWidth}
					boxImageHeight={imageHeight}
				/>;

			// Description
			let escalePresentation = "";
			if (typeof(this.props.appData.escales[this.selectedEscaleIndex].presentation) !== "undefined"
				&& this.props.appData.escales[this.selectedEscaleIndex].presentation.length > 0) {
				escalePresentation = <p>{nl2br(escStr(this.props.appData.escales[this.selectedEscaleIndex].presentation))}</p>;
			}

			// Link
			let escaleLink = "";
			if (typeof(this.props.appData.escales[this.selectedEscaleIndex].link_text) !== "undefined"
				&& this.props.appData.escales[this.selectedEscaleIndex].link_text.length > 0
				&& typeof(this.props.appData.escales[this.selectedEscaleIndex].link_url) !== "undefined"
				&& this.props.appData.escales[this.selectedEscaleIndex].link_url.length > 0) {
				escaleLink = <p><a style={{color:this.props.appOptions.primaryColor}} rel="noopener noreferrer" target="_blank" href={this.props.appData.escales[this.selectedEscaleIndex].link_url}>{escStr(this.props.appData.escales[this.selectedEscaleIndex].link_text)}</a></p>;
			}

			// Location
			let escaleLocation = "";
			if (typeof(this.props.appData.escales[this.selectedEscaleIndex].adresse) !== "undefined"
					&& this.props.appData.escales[this.selectedEscaleIndex].adresse !== null
					&& this.props.appData.escales[this.selectedEscaleIndex].adresse.length > 0) {
				escaleLocation =
					<li>
						<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.props.appData.escales[this.selectedEscaleIndex].latitude, this.props.appData.escales[this.selectedEscaleIndex].longitude)}>
							<IoLocationSharp size="30px" />
							<span className="ww-partenaireInfos__texte">{nl2br(escStr(this.props.appData.escales[this.selectedEscaleIndex].adresse))}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}
			else {
				escaleLocation =
					<li>
						<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.props.appData.escales[this.selectedEscaleIndex].latitude, this.props.appData.escales[this.selectedEscaleIndex].longitude)}>
							<IoLocationSharp size="30px" />
							<span className="ww-partenaireInfos__texte">{this.props.appData.escales[this.selectedEscaleIndex].latitude} | {this.props.appData.escales[this.selectedEscaleIndex].longitude}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}

			// Share
			let share = '';
			if (typeof(this.props.appData.escales[this.selectedEscaleIndex].link) !== 'undefined'
				&& this.props.appData.escales[this.selectedEscaleIndex].link.length > 0) {
				const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.props.appData.escales[this.selectedEscaleIndex].link;
				const twitterUrl = 'https://twitter.com/intent/tweet/?text=' + this.props.appData.escales[this.selectedEscaleIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.escales[this.selectedEscaleIndex].link;
				const linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&title=' + this.props.appData.escales[this.selectedEscaleIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.escales[this.selectedEscaleIndex].link;
				share =
					<div className="ww-share">
						<p className="ww-share__title">{escStr(this.props.appData.tStrings.partager)}</p>
						<ul>
							<li><a href={facebookUrl} target="_blank" rel="noreferrer noopener"><FaFacebookF size="24px" /></a></li>
							<li><a href={twitterUrl} target="_blank" rel="noreferrer noopener"><FaTwitter size="22px" /></a></li>
							<li><a href={linkedInUrl} target="_blank" rel="noreferrer noopener"><FaLinkedinIn size="22px" /></a></li>
						</ul>
					</div>;
			}

			// Infos List
			const escaleInfos =
				<ul className="ww-partenaireInfos">
					{escaleLocation}
				</ul>;


			///////////////////////////////////////
			// Page Content
			pageClass = 'page-postViewer page-escaleViewer';
			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{feature}
					<h1>{escStr(this.props.appData.escales[this.selectedEscaleIndex].title)}</h1>
					<div id="postViewer__content">
						{escalePresentation}
						{escaleLink}
						{escaleInfos}
					</div>
					{share}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;
		}
		else {
			pageClass = "page-postViewer page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}

		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}

}

export default EscaleViewer;
