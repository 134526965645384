// React
import React from 'react';

// Tools / Library
import {escStr} from '../Utils'
import {MdKeyboardArrowLeft} from 'react-icons/md';

// Components
import Navbar from './Navbar';

const nl2br = require('react-nl2br');


class VisitsDuree extends React.Component {

	///////////////////////////////////////
	// Variables
	dureeValue = 1;

	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Bind this to functions
		this.numberClick = this.numberClick.bind(this);
		this.validate = this.validate.bind(this);
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		let pageContent = "";
		pageContent =
			<div className="ww-clock">
				<div className="ww-clock__circle"></div>
				<div className="ww-clock__ticks">
					<div className="ww-clock__tick ww-clock__tick--1"></div>
					<div className="ww-clock__tick ww-clock__tick--2"></div>
					<div className="ww-clock__tick ww-clock__tick--3"></div>
					<div className="ww-clock__tick ww-clock__tick--4"></div>
					<div className="ww-clock__tick ww-clock__tick--5"></div>
					<div className="ww-clock__tick ww-clock__tick--6"></div>
					<div className="ww-clock__tick ww-clock__tick--7"></div>
					<div className="ww-clock__tick ww-clock__tick--8"></div>
					<div className="ww-clock__tick ww-clock__tick--9"></div>
					<div className="ww-clock__tick ww-clock__tick--10"></div>
					<div className="ww-clock__tick ww-clock__tick--11"></div>
					<div className="ww-clock__tick ww-clock__tick--12"></div>
				</div>
				<div className="ww-clock__number ww-clock__number--1" onClick={ () => this.numberClick(3) }>3H</div>
				<div className="ww-clock__numberCircle ww-clock__number--1" onClick={ () => this.numberClick(3) }></div>
				<div className="ww-clock__numberCircleShadow ww-clock__number--1" onClick={ () => this.numberClick(3) }></div>
				<div className="ww-clock__number ww-clock__number--2"  onClick={ () => this.numberClick(2) }>2H</div>
				<div className="ww-clock__numberCircle ww-clock__number--2"  onClick={ () => this.numberClick(2) }></div>
				<div className="ww-clock__numberCircleShadow ww-clock__number--2" onClick={ () => this.numberClick(2) }></div>
				<div className="ww-clock__number ww-clock__number--3" onClick={ () => this.numberClick(1) }>1H</div>
				<div className="ww-clock__numberCircle ww-clock__number--3" onClick={ () => this.numberClick(1) }></div>
				<div className="ww-clock__numberCircleShadow ww-clock__number--3" onClick={ () => this.numberClick(1) }></div>
				<div className="ww-clock__slider">
					<div className="ww-clock__selection"></div>
					<div className="ww-clock__holder">1H</div>
				</div>
				<div className="ww-clock__validate" onClick={ () => this.validate() }></div>
			</div>;


		// Page Title
		let title = '';
		for (let i=0;i<this.props.appData.contents.length;i++) {
			if (this.props.appData.contents[i].special === 'visites') {
				title = this.props.appData.contents[i].title;
				break;
			}
		}


		return(
			<div className="page-clock">
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					<h2 className="ww-boxList__title">{escStr(title)}</h2>
					<p className="ww-clock__instructions">{nl2br(escStr(this.props.appData.tStrings.visite_choisir_duree))}</p>
					{pageContent}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>
			</div>
		);
	}


	///////////////////////////////////////
	// Hour click
	numberClick(value) {
		this.dureeValue = value;
		let output = document.getElementsByClassName("ww-clock__selection")[0];
		if (typeof(output) !== "undefined") {
			if (value === 1) { output.style = "clip-path: polygon(0% 20%, 0% 0%, 50% 0%, 50% 50%);"; }
			if (value === 2) { output.style = "clip-path: polygon(45% 100%, 0% 100%, 0% 0%, 50% 0%, 50% 50%);"; }
			if (value === 3) { output.style = "clip-path: polygon(100% 25%, 100% 100%, 0% 100%, 0% 0%, 50% 0%, 50% 50%);"; }
		}
		let text = document.getElementsByClassName("ww-clock__holder")[0];
		if (typeof(output) !== "undefined") {
			if (value === 1) { text.innerHTML = "1H"; }
			if (value === 2) { text.innerHTML = "2H"; }
			if (value === 3) { text.innerHTML = "3H"; }
		}
	}


	///////////////////////////////////////
	// Validate
	validate() {
		this.props.handleClickEvent('visits_duree', this.dureeValue, this.props.historyProps);
	}

}

export default VisitsDuree;
