// React
import React from 'react';

// Tools / Library
import {escStr, processGeolocationDistance, mapsURL, fetchWithTimeout} from '../Utils'
import {IoLocationSharp} from 'react-icons/io5';
import {IoIosArrowDropright, IoIosGlobe} from 'react-icons/io';
import {FaPhoneAlt} from 'react-icons/fa';
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {AiFillStar, AiOutlineStar} from 'react-icons/ai';
import {FaFacebookF, FaTwitter, FaLinkedinIn} from 'react-icons/fa';


// Components
import UnknownScreen from './UnknownScreen';
import Navbar from './Navbar';
import Box from './Box';

const nl2br = require('react-nl2br');


class PartenaireViewer extends React.Component {

	///////////////////////////////////////
	// Variables
	mounted = false;
	selectedPartenaireIndex = -1;


	///////////////////////////////////////
	// Constructor
	constructor(props) {
		super(props);

		// Init state
		this.state = {
			formRate: 0,
			formNom: '',
			formMessage: '',
			formNotice: '',
			formSent: false,
		};

		// Bind this to functions
		this.handleChange = this.handleChange.bind(this);
		this.sendAvisForm = this.sendAvisForm.bind(this);
	}


	///////////////////////////////////////
	// Component Did Mount
	componentDidMount() {
		this.mounted = true;
		window.scrollTo(0, 0);
	}


	///////////////////////////////////////
	// Component Will Unmount
	componentWillUnmount() {
		this.mounted = false;
		if (typeof(this.props.historyHandler) !== "undefined") { this.props.historyHandler(this.props.historyProps); }
	}


	///////////////////////////////////////
	// Render
	render() {

		// Determine Index
		if (this.selectedPartenaireIndex === -1) {
			for (let i=0; i<this.props.appData.partenaires.length; i++) {
				if (this.props.appData.partenaires[i].id === parseInt(this.props.partenaireId, 10)) {
					this.selectedPartenaireIndex = i;
					break;
				}
			}
		}

		let pageClass = '';
		let pageContent = '';

		if (this.selectedPartenaireIndex !== -1) {

			// Feature Image
			let imageUrl = this.props.appData.partenaires[this.selectedPartenaireIndex].image;
			let imageWidth = this.props.appData.partenaires[this.selectedPartenaireIndex].image_width;
			let imageHeight = this.props.appData.partenaires[this.selectedPartenaireIndex].image_height;
			if (imageUrl.length === 0) {
				imageUrl = this.props.appData.default_image.url;
				imageWidth = this.props.appData.default_image.width;
				imageHeight = this.props.appData.default_image.height;
			}
			const feature =
				<Box
					boxClass='ww-box__nooverlay'
					boxImage={imageUrl}
					boxImageWidth={imageWidth}
					boxImageHeight={imageHeight}
				/>;

			// Note / Distance
			let distance = '';
			// Get the last geolocation
			if (typeof(Storage) !== 'undefined') {
				const lsLastGeolocationTs = localStorage.getItem('lastGeolocationTs');
				if (typeof(lsLastGeolocationTs) !== 'undefined'
					&& lsLastGeolocationTs !== null
					&& (Date.now() - lsLastGeolocationTs < 60 * 60 * 1000)
				) {
					const lsLastGeolocationLatitude = localStorage.getItem('lastGeolocationLatitude');
					const lsLastGeolocationLongitude = localStorage.getItem('lastGeolocationLongitude');
					if (typeof(lsLastGeolocationLatitude) !== 'undefined'
						&& lsLastGeolocationLatitude !== null
						&& typeof(lsLastGeolocationLongitude) !== 'undefined'
						&& lsLastGeolocationLongitude !== null) {
						distance =
							<p className="ww-partenaireNoteDistance__distance">
								<IoLocationSharp size="14px" />
								{escStr(this.props.appData.tStrings.vous_etes_a) + ' ' + processGeolocationDistance(lsLastGeolocationLatitude, lsLastGeolocationLongitude, this.props.appData.partenaires[this.selectedPartenaireIndex].latitude, this.props.appData.partenaires[this.selectedPartenaireIndex].longitude, true) + ' ' + this.props.appData.tStrings.de_ce_lieu}
							</p>;
					}
				}
			}

			let note = '';
			if (this.props.appData.partenaires[this.selectedPartenaireIndex].notes_enabled === true) {
				const moyenne = (this.props.appData.partenaires[this.selectedPartenaireIndex].notes.moyenne !== 0) ? this.props.appData.partenaires[this.selectedPartenaireIndex].notes.moyenne : '-';
				note =
					<div className="ww-partenaireNoteDistance__note">
						<AiFillStar size="70px" />
						<span className="ww-partenaireNoteDistance__moyenne">{moyenne}/5</span>
						<span className="ww-partenaireNoteDistance__avis">({this.props.appData.partenaires[this.selectedPartenaireIndex].notes.nb} avis)</span>
					</div>;
			}
			const noteDistance =
				<div className="ww-partenaireNoteDistance">
					{distance}
					{note}
				</div>;

			// Categorie
			let partenaireCategorie = "";
			for (let i=0; i<this.props.appData.partenaires[this.selectedPartenaireIndex].categories.length; i++) {
				for (let j=0; j<this.props.appData.partenaires_categories.length; j++) {
					if (this.props.appData.partenaires[this.selectedPartenaireIndex].categories[i] === this.props.appData.partenaires_categories[j].id) {
						if (partenaireCategorie.length === 0) { partenaireCategorie += this.props.appData.partenaires_categories[j].name; }
						else { partenaireCategorie += ', ' + this.props.appData.partenaires_categories[j].name; }
					}
				}
			}

			// Description
			let partenairePresentation = "";
			if (typeof(this.props.appData.partenaires[this.selectedPartenaireIndex].presentation) !== "undefined"
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].presentation.length > 0) {
				partenairePresentation = <p>{nl2br(escStr(this.props.appData.partenaires[this.selectedPartenaireIndex].presentation))}</p>;
			}

			// Location
			let partenaireLocation = "";
			if (typeof(this.props.appData.partenaires[this.selectedPartenaireIndex].adresse) !== "undefined"
					&& this.props.appData.partenaires[this.selectedPartenaireIndex].adresse !== null
					&& this.props.appData.partenaires[this.selectedPartenaireIndex].adresse.length > 0) {
				partenaireLocation =
					<li>
						<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.props.appData.partenaires[this.selectedPartenaireIndex].latitude, this.props.appData.partenaires[this.selectedPartenaireIndex].longitude)}>
							<IoLocationSharp size="30px" />
							<span className="ww-partenaireInfos__texte">{nl2br(escStr(this.props.appData.partenaires[this.selectedPartenaireIndex].adresse))}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}
			else {
				partenaireLocation =
					<li>
						<a target="_blank" rel="noopener noreferrer" href={mapsURL(this.props.appData.partenaires[this.selectedPartenaireIndex].latitude, this.props.appData.partenaires[this.selectedPartenaireIndex].longitude)}>
							<IoLocationSharp size="30px" />
							<span className="ww-partenaireInfos__texte">{this.props.appData.partenaires[this.selectedPartenaireIndex].latitude} | {this.props.appData.partenaires[this.selectedPartenaireIndex].longitude}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_carte)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}

			// Website
			let partenaireWebsite = "";
			if (typeof(this.props.appData.partenaires[this.selectedPartenaireIndex].website) !== "undefined"
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].website !== null
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].website.length > 0) {
				partenaireWebsite =
					<li className="ww-partenaireInfos__nowrap">
						<a target="_blank" rel="noopener noreferrer" href={this.props.appData.partenaires[this.selectedPartenaireIndex].website}>
							<IoIosGlobe size="26px" />
							<span className="ww-partenaireInfos__texte">{this.props.appData.tStrings.website}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_site)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}

			// Telephone
			let partenaireTelephone = "";
			if (typeof(this.props.appData.partenaires[this.selectedPartenaireIndex].telephone) !== "undefined"
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].telephone !== null
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].telephone.length > 0) {
				let phone = this.props.appData.partenaires[this.selectedPartenaireIndex].telephone.replace(/\./g, '');
				phone = phone.replace(/\s/g, '');
				phone = 'tel:' + phone;
				partenaireTelephone =
					<li>
						<a target="_blank" rel="noopener noreferrer" href={phone}>
							<FaPhoneAlt size="22px" />
							<span className="ww-partenaireInfos__texte">{this.props.appData.partenaires[this.selectedPartenaireIndex].telephone}</span>
							<span className="ww-partenaireInfos__comment">{escStr(this.props.appData.tStrings.click_appeler)}</span>
							<IoIosArrowDropright size="32px" className="ww-arrow" />
						</a>
					</li>;
			}

			// Share
			let share = '';
			if (typeof(this.props.appData.partenaires[this.selectedPartenaireIndex].link) !== 'undefined'
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].link.length > 0) {
				const facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + this.props.appData.partenaires[this.selectedPartenaireIndex].link;
				const twitterUrl = 'https://twitter.com/intent/tweet/?text=' + this.props.appData.partenaires[this.selectedPartenaireIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.partenaires[this.selectedPartenaireIndex].link;
				const linkedInUrl = 'https://www.linkedin.com/shareArticle?mini=true&title=' + this.props.appData.partenaires[this.selectedPartenaireIndex].title.replace(/ /g, '+') + '&url=' + this.props.appData.partenaires[this.selectedPartenaireIndex].link;
				share =
					<div className="ww-share">
						<p className="ww-share__title">{escStr(this.props.appData.tStrings.partager)}</p>
						<ul>
							<li><a href={facebookUrl} target="_blank" rel="noreferrer noopener"><FaFacebookF size="24px" /></a></li>
							<li><a href={twitterUrl} target="_blank" rel="noreferrer noopener"><FaTwitter size="22px" /></a></li>
							<li><a href={linkedInUrl} target="_blank" rel="noreferrer noopener"><FaLinkedinIn size="22px" /></a></li>
						</ul>
					</div>;
			}

			// Infos List
			const partenaireInfos =
				<ul className="ww-partenaireInfos">
					{partenaireLocation}
					{partenaireWebsite}
					{partenaireTelephone}
				</ul>;



			///////////////////////////////////////
			// Prochain Evenement
			let prochainEvenement = '';
			if (this.props.appData.agenda.length > 0) {
				for (let i=0;i<this.props.appData.agenda.length;i++) {
					if (this.props.appData.agenda[i].partenaire === this.props.appData.partenaires[this.selectedPartenaireIndex].id) {
						let eventImage = '';
						if (this.props.appData.agenda[i].image.length > 0) {
							eventImage =
								<div className="ww-partenaireProchainEvenement__image">
									<img src={this.props.appData.agenda[i].image} alt="Évènement" />
								</div>;
						}
						let more = '';
						if (this.props.appData.agenda[i].url.length > 0) {
							more =
								<p className="ww-partenaireProchainEvenement__more">
									<a className="uppercase" target="_blank" rel="noopener noreferrer" href={this.props.appData.agenda[i].url}>{escStr(this.props.appData.tStrings.en_savoir_plus)}</a>
								</p>;
						}

						prochainEvenement =
							<div className="ww-partenaireProchainEvenement">
								<h2 className="ww-sectionSeparator">{escStr(this.props.appData.tStrings.prochain_evenement)}</h2>
								<div className="ww-partenaireProchainEvenement__evenement">
									{eventImage}
									<p className="ww-partenaireProchainEvenement__date">{this.props.appData.agenda[i].date}</p>
									<p className="ww-partenaireProchainEvenement__titre">{escStr(this.props.appData.agenda[i].titre)}</p>
									<p className="ww-partenaireProchainEvenement__description">{nl2br(escStr(this.props.appData.agenda[i].description))}</p>
									{more}
								</div>
							</div>;
						break;
					}
				}
			}


			///////////////////////////////////////
			// Formulaire Donnez votre avis
			let avisFormulaire = '';
			let reviewedPartenaires = [];
			const lsReviewedPartenaires = localStorage.getItem('reviewedPartenaires');
			if (typeof(lsReviewedPartenaires) !== 'undefined' && lsReviewedPartenaires !== null) { reviewedPartenaires = JSON.parse(lsReviewedPartenaires); }
			if (!reviewedPartenaires.includes(this.props.appData.partenaires[this.selectedPartenaireIndex].id) || this.state.formSent) {
				if (this.state.formSent) {
					avisFormulaire =
						<div className="ww-partenaireAvisForm">
							<h2 className="ww-sectionSeparator">{escStr(this.props.appData.tStrings.donnez_votre_avis)}</h2>
							<p>{nl2br(escStr(this.props.appData.tStrings.avis_remerciement))}</p>
						</div>;
				}
				else {

					// Commentaire
					let comment = '';
					if (this.props.appData.partenaires[this.selectedPartenaireIndex].avis_enabled === true) {
						comment =
							<div>
								<label>{escStr(this.props.appData.tStrings.nom)}</label>
								<input type="text" name="nom" onChange={ (e) => this.handleChange('nom', e.target.value)} />
								<label>{escStr(this.props.appData.tStrings.message)}</label>
								<textarea name="message" onChange={ (e) => this.handleChange('message', e.target.value)}></textarea>
							</div>;
					}

					// Note
					let noteForm = '';
					if (this.props.appData.partenaires[this.selectedPartenaireIndex].notes_enabled === true) {
						const star1 = (this.state.formRate >= 1) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
						const star2 = (this.state.formRate >= 2) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
						const star3 = (this.state.formRate >= 3) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
						const star4 = (this.state.formRate >= 4) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
						const star5 = (this.state.formRate >= 5) ? <AiFillStar size="40px" /> : <AiOutlineStar size="40px" />;
						noteForm =
							<div>
								<label>{escStr(this.props.appData.tStrings.note)}</label>
								<ul className="ww-partenaireAvisFormNote">
									<li onClick={ () => this.handleChange('note', 1) }>{star1}</li>
									<li onClick={ () => this.handleChange('note', 2) }>{star2}</li>
									<li onClick={ () => this.handleChange('note', 3) }>{star3}</li>
									<li onClick={ () => this.handleChange('note', 4) }>{star4}</li>
									<li onClick={ () => this.handleChange('note', 5) }>{star5}</li>
								</ul>
							</div>;
					}

					// Notice
					let noticeForm = '';
					if (this.state.formNotice.length > 0) { noticeForm = <p id="ww-form__message">{this.state.formNotice}</p>; }

					if (this.props.appData.partenaires[this.selectedPartenaireIndex].avis_enabled === true || this.props.appData.partenaires[this.selectedPartenaireIndex].notes_enabled === true) {
					avisFormulaire =
						<div className="ww-partenaireAvisForm">
							<h2 className="ww-sectionSeparator">{escStr(this.props.appData.tStrings.donnez_votre_avis)}</h2>
							<form className="ww-form">
								{noteForm}
								{comment}
								<button className="ww-button--primary" type="button" onClick={this.sendAvisForm}>{escStr(this.props.appData.tStrings.envoyer)}</button>
								{noticeForm}
							</form>
						</div>;
					}
				}
			}


			///////////////////////////////////////
			// Liste des avis
			let avisListe = '';
			let avisCount = 0;
			if (this.props.appData.partenaires[this.selectedPartenaireIndex].avis_enabled === true
				&& this.props.appData.partenaires[this.selectedPartenaireIndex].avis.length > 0) {
				avisListe =
					<div className="ww-partenaireAvis__liste">
						<h2 className="ww-sectionSeparator">{escStr(this.props.appData.tStrings.avis)}</h2>
						<ul>
							{this.props.appData.partenaires[this.selectedPartenaireIndex].avis.map((avis, index) => {
								if (avis.message.length === 0) { return ''; }
								avisCount++;
								let avisNom = (avis.nom.length > 0) ? <p className="ww-partenaireAvis__nom">{escStr(avis.nom)}</p> : '';
								let avisReponse = (avis.reponse.length > 0) ? <p className="ww-partenaireAvis__reponse"><span>{escStr(this.props.appData.tStrings.reponse_lieu)}</span>{nl2br(escStr(avis.reponse))}</p> : '';
								return (
									<li key={index}>
										<p className="ww-partenaireAvis__date">{avis.date}</p>
										{avisNom}
										<p className="ww-partenaireAvis__message">{nl2br(escStr(avis.message))}</p>
										{avisReponse}
									</li>
								);
							})}
						</ul>
					</div>;
			}
			if (avisCount === 0) { avisListe = ''; }


			///////////////////////////////////////
			// Page Content
			pageClass = 'page-postViewer page-partenaireViewer';
			pageContent =
				<div className="page-content">
					<Navbar
						historyProps={this.props.historyProps}
						handleClickEvent={this.props.handleClickEvent}
						appOptions={this.props.appOptions}
						appData={this.props.appData}
						splashData={this.props.splashData}
					/>
					{feature}
					{noteDistance}
					<h1>{escStr(this.props.appData.partenaires[this.selectedPartenaireIndex].title)}</h1>
					<p className="ww-page__taxonomy">{escStr(partenaireCategorie)}</p>
					<div id="postViewer__content">
						{partenairePresentation}
						{partenaireInfos}
					</div>
					{share}
					{prochainEvenement}
					{avisFormulaire}
					{avisListe}
					<div className="ww-bottomButton ww-button--primary" onClick={ () => this.props.handleClickEvent("generic_back", null, this.props.historyProps) }>
						<span><MdKeyboardArrowLeft size="26px" />{escStr(this.props.appData.tStrings.retour)}</span>
					</div>
				</div>;
		}
		else {
			pageClass = "page-postViewer page-pageUnknown";
			pageContent =
				<UnknownScreen
					appOptions={this.props.appOptions}
					appData={this.props.appData}
				/>;
		}

		return(
			<div className={pageClass}>
				{pageContent}
			</div>
		);
	}


	///////////////////////////////////////
	// Handle Change
	handleChange(name, value) {

		if (name === 'note') {
			this.setState({formRate: value});
		}
		if (name === 'nom') {
			this.setState({formNom: value});
		}
		if (name === 'message') {
			this.setState({formMessage: value});
		}

	}


	///////////////////////////////////////
	// Send Avis Form
	sendAvisForm(){

		if (this.state.formRate === 0) { this.setState({formNotice: escStr(this.props.appData.tStrings.signalement_formulaire_incomplet)}); return; }
		if (this.props.appData.partenaires[this.selectedPartenaireIndex].avis_enabled === true && this.state.formMessage === '') { this.setState({formNotice: escStr(this.props.appData.tStrings.signalement_formulaire_incomplet)}); return; }
		if (this.selectedPartenaireIndex === -1) { return; }

		const formData = new FormData();
		formData.append('apikey', this.props.appOptions.backofficeAPIKey);
		formData.append('version', this.props.appOptions.version);
		formData.append('language', this.props.splashData.code);
		formData.append('order', 'partenaire_avis');
		formData.append('partenaire_id', this.props.appData.partenaires[this.selectedPartenaireIndex].id);
		formData.append('note', this.state.formRate);
		formData.append('nom', this.state.formNom);
		formData.append('message', this.state.formMessage);
		const fetchParams = {
			method: 'POST',
			body: formData,
		};

		fetchWithTimeout(this.props.appOptions.backofficeSubmitUrl, fetchParams, 10000)
			.then(response => response.json())
			.then(result => {});

		// Update list of reviewed partenaires
		let reviewedPartenaires = [];
		const lsReviewedPartenaires = localStorage.getItem('reviewedPartenaires');
		if (typeof(lsReviewedPartenaires) !== 'undefined' && lsReviewedPartenaires !== null) { reviewedPartenaires = JSON.parse(lsReviewedPartenaires); }
		if (!reviewedPartenaires.includes(this.props.appData.partenaires[this.selectedPartenaireIndex].id)) {
			reviewedPartenaires.push(this.props.appData.partenaires[this.selectedPartenaireIndex].id);
			localStorage.setItem('reviewedPartenaires', JSON.stringify(reviewedPartenaires));
		}

		this.setState({formSent: true, formNotice: ''});
	}

}

export default PartenaireViewer;
